import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import gksLogo from 'assets/img/gksLogo.png';

import PoweredBy from 'components/PoweredBy/PoweredBy';
import BuiltOnEnkrateia from 'components/BuiltOnEnkrateia/BuiltOnEnkrateia';
import Markdown from 'components/Markdown/Markdown';
import { useSystemSettings } from 'providers/SystemSettingsProvider';

import './Splash.scss';

export interface SplashProps {}

const Splash: FC<SplashProps> = () => {
  const { t } = useTranslation();
  const { splash_image, splash_text } = useSystemSettings();

  return (
    <div className="enkrateia-splash" style={{ backgroundImage: `url(${splash_image})` }}>
      <div className="splash-overlay" />
      <div className="content">
        <Markdown linkTarget="_blank" className="cta">
          {splash_text}
        </Markdown>
      </div>
      <div className="footer">
        <BuiltOnEnkrateia />
        <img src={gksLogo} alt={t('pages.login.gksLogo') as string} />
        <PoweredBy />
      </div>
    </div>
  );
};

export default Splash;
