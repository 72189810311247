import React from 'react';

import './Panel.scss';

export interface PanelProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const Panel = ({ className = '', children }: PanelProps) => (
  <div className={`enkrateia-panel ${className}`}>{children}</div>
);

export default Panel;
