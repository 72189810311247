import React from 'react';
import { useTranslation } from 'react-i18next';

import TraroElement from 'types/api/TraroElement';
import TraroElementResponse from 'types/api/TraroElementResponse';
import CollapsibleSection from 'components/CollapsibleSection/CollapsibleSection';
import Panel from 'components/Panel/Panel';
import useUser from 'hooks/useUser';

import ReviewerScoringAndFeedback from './components/ReviewerScoringAndFeedback/ReviewerScoringAndFeedback';
import ProponentScoringAndFeedback from './components/ProponentScoringAndFeedback/ProponentScoringAndFeedback';

import './ScoringAndFeedback.scss';

export interface ScoringAndFeedbackProps {
  traroElement: TraroElement;
  rfpElementResponse: TraroElementResponse;
}

const ScoringAndFeedback = ({ traroElement, rfpElementResponse }: ScoringAndFeedbackProps) => {
  const { t } = useTranslation();
  const score =
    Math.round(
      rfpElementResponse.traro_element_score.reduce((acc, elementScore) => acc + elementScore.question_score, 0) * 100,
    ) / 100;
  const maxScore = traroElement.max_score;
  const { isReviewer } = useUser();

  if (!isReviewer && rfpElementResponse.traro_element_score.length === 0) {
    return null;
  }

  return (
    <Panel className="enkrateia-scoring-and-feedback">
      <CollapsibleSection title={t('pages.traroElement.scoringAndFeedback.title')} defaultExpanded>
        {isReviewer ? (
          <ReviewerScoringAndFeedback traroElement={traroElement} rfpElementResponse={rfpElementResponse} />
        ) : (
          <ProponentScoringAndFeedback traroElement={traroElement} rfpElementResponse={rfpElementResponse} />
        )}
        <div className="total-score">{t('pages.traroElement.scoringAndFeedback.totalScore', { score, maxScore })}</div>
      </CollapsibleSection>
    </Panel>
  );
};

export default ScoringAndFeedback;
