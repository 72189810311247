/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { FormikProps } from 'formik/dist/types';

import { useRfpElementTickets } from 'api';
import useKliparoElementForm, { FormValues } from 'components/QuestionResponse/hooks/useKliparoElementForm';
import QuestionStatusButton from 'components/QuestionStatusButton/QuestionStatusButton';
import ProblemSpringerArc from 'components/ProblemSpringerArc/ProblemSpringerArc';
import CreateTicketButton from 'components/CreateTicketButton/CreateTicketButton';
import QuestionResponse from 'components/QuestionResponse/QuestionResponse';
import GoBackPanel from 'components/GoBackPanel/GoBackPanel';
import AutoSaveWatcher from 'components/AutoSaveWatcher';
import Tabs, { TabsRef } from 'components/Tabs/Tabs';
import SideBar from 'components/SideBar/SideBar';
import Scoring from 'components/Scoring/Scoring';
import Header from 'components/Header/Header';
import useUser from 'hooks/useUser';
import { useSelector } from 'store';
import { canAnswerRFPQuestions } from 'services/permissions';

import './TraroElementPage.scss';

import useTraroElement from './hooks/useTraroElement';
import ElementContext from './components/ElementContext/ElementContext';
import TraroElementSubmittedModal from './components/TraroElementSubmittedModal/TraroElementSubmittedModal';
import History from './components/History/History';
import ElementResponseTickets from './components/ElementResponseTickets/ElementResponseTickets';
import Navigation from './components/Navigation/Navigation';
import useInitialTab from './hooks/useInitialTab';
import ScoringAndFeedback from './components/ScoringAndFeedback/ScoringAndFeedback';

export interface TraroElementPageProps {}

const TraroElementPage: FC<TraroElementPageProps> = () => {
  const user = useUser();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues>>(null);

  const rfpResponse = useSelector((store) => store.rfpResponse.data);
  const { traroElement, traroElementResponse, rfpResponseId, rfpId, traroElementSpecific } = useTraroElement();

  const tabsRef = useRef<TabsRef>({ setActiveKey: () => null });
  const { data: tickets } = useRfpElementTickets(traroElementResponse.id);
  const { schema, initialValues, onSubmit, onAutoSave } = useKliparoElementForm(
    { traro_element: traroElement, rfpElementResponse: traroElementResponse },
    formRef,
  );

  const { initialTab, activeTab, onTabSwitch } = useInitialTab();

  return (
    <div className="enkrateia-element-page">
      <Header />
      <div className="content">
        <SideBar variant="traro" collapsible initiallyCollapsed={user.isReviewer}>
          <ProblemSpringerArc title={traroElement.element_name} activeElement={traroElementSpecific} />
          <Scoring
            score={traroElementResponse.question_score ?? '-'}
            maxScore={traroElement.max_score ?? 0}
            scoringRubric={traroElement.scoring_rubric}
          />
          <QuestionStatusButton status={traroElementResponse.question_status} readonly />
          <History elementId={traroElementResponse.id} traroElement={traroElement} />
        </SideBar>
        <main>
          <div>
            <GoBackPanel
              variant="plain"
              target={`/rfp/${rfpId}/rfp-response/${rfpResponseId}`}
              label={t('pages.traroElement.goBack')}
            />
            <Navigation traroElement={traroElement} />
            <Tabs
              initialTab={initialTab}
              innerRef={tabsRef}
              postTabNamesNode={<CreateTicketButton traroElementResponse={traroElementResponse} rfpRelated />}
              onActiveTabChange={onTabSwitch}
            >
              <Tabs.Tab tabKey="context" label={t('pages.traroElement.tabContext')}>
                <ElementContext traroElement={traroElement} questionStatus={traroElementResponse.question_status} />
              </Tabs.Tab>
              <Tabs.Tab tabKey="question" label={t('pages.traroElement.tabQuestion')}>
                <Formik
                  validationSchema={schema}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validateOnMount
                  enableReinitialize
                  innerRef={formRef}
                  key={traroElement.arch_element_specific}
                >
                  <Form>
                    <AutoSaveWatcher
                      manualSubmit={onAutoSave}
                      allowInvalidSubmission
                      disabled={!canAnswerRFPQuestions(user, rfpResponse, traroElementResponse)}
                    >
                      <QuestionResponse traroElement={traroElement} rfpElementResponse={traroElementResponse} />
                    </AutoSaveWatcher>
                  </Form>
                </Formik>
              </Tabs.Tab>
            </Tabs>
          </div>
          {activeTab === 'question' && (
            <div className="right-side-bar">
              <ScoringAndFeedback traroElement={traroElement} rfpElementResponse={traroElementResponse} />
              <ElementResponseTickets tickets={tickets} rfpElementResponse={traroElementResponse} />
            </div>
          )}
        </main>
      </div>
      <TraroElementSubmittedModal springerArcTitle={traroElement.element_name} />;
    </div>
  );
};

export default TraroElementPage;
