import React from 'react';
import { useTranslation } from 'react-i18next';

import LinkButton from 'components/LinkButton/LinkButton';
import RFPResponseStatus from 'types/RFPResponseStatus';
import { ExternalLinkIcon } from 'components/SvgIcons';
import useUser from 'hooks/useUser';
import { useSelector } from 'store';

import StartReviewProcess from './components/StartReviewProcess/StartReviewProcess';

import './Actions.scss';

const Actions = () => {
  const { t } = useTranslation();

  const { rfp_response_status } = useSelector((store) => store.rfpResponse.data);
  const { isReviewer } = useUser();

  return (
    <div className="enkrateia-rfp-response-actions">
      <h2>{t('pages.projectOverview.mainPanel.actions')}</h2>
      <div className="actions-row">
        {rfp_response_status === RFPResponseStatus.IN_REVIEW && <StartReviewProcess />}
        {isReviewer && (
          <LinkButton to="condensed" relative="path" target="_blank" className="enkrateia-open-condensed-view">
            <ExternalLinkIcon size={22} color="#000" />
            {t('pages.projectOverview.mainPanel.condensedView.linkButton')}
          </LinkButton>
        )}
      </div>
    </div>
  );
};

export default Actions;
