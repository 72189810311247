import React, { FC } from 'react';

import TicketDto from 'types/api/TicketDto';
import TicketSourceType from 'types/TicketSourceType';
import TraroElementResponse from 'types/api/TraroElementResponse';
import Panel from 'components/Panel/Panel';

import TicketsCategory from './components/TicketsCategory/TicketsCategory';

import './ElementResponseTickets.scss';
export interface TicketsProps {
  tickets: TicketDto[] | undefined;
  rfpElementResponse: TraroElementResponse;
}

const ElementTickets: FC<TicketsProps> = ({ tickets, rfpElementResponse }) => {
  if (!tickets || tickets.length === 0) {
    return null;
  }

  return (
    <Panel className="enkrateia-element-response-tickets">
      <h2>Tickets ({tickets.length})</h2>
      <TicketsCategory
        category={TicketSourceType.SOURCE_NOT_ASSIGNED}
        tickets={tickets}
        rfpElementResponse={rfpElementResponse}
      />
      <TicketsCategory
        category={TicketSourceType.SOURCE_REVIEW}
        tickets={tickets}
        rfpElementResponse={rfpElementResponse}
      />
      <TicketsCategory
        category={TicketSourceType.SOURCE_QUESTION_TYPE}
        tickets={tickets}
        rfpElementResponse={rfpElementResponse}
      />
      <TicketsCategory
        category={TicketSourceType.SOURCE_REMEDIAL_ACTION}
        tickets={tickets}
        rfpElementResponse={rfpElementResponse}
      />
      <TicketsCategory
        category={TicketSourceType.SOURCE_IT_ISSUE}
        tickets={tickets}
        rfpElementResponse={rfpElementResponse}
      />
    </Panel>
  );
};

export default ElementTickets;
