import { createContext, useContext } from 'react';

/**
 * Contains information about currently active tab.
 */
const TabsContext = createContext<{
  activeKey: string;
  setActiveKey: (key: string) => void;
}>({
  activeKey: '',
  setActiveKey: () => null,
});

export const useTabsContext = () => useContext(TabsContext);

export default TabsContext;
