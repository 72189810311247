import React, { useRef } from 'react';
import { Form, Formik, FormikProps } from 'formik';

import TraroElement from 'types/api/TraroElement';
import TraroElementResponse from 'types/api/TraroElementResponse';
import { Table } from 'components/Table';
import AutoSaveWatcher from 'components/AutoSaveWatcher';
import useElementScoresWithResponses from 'hooks/useElementScoresWithResponses/useElementScoresWithResponses';

import useColumns from './hooks/useColumns';
import useInitialValues, { ElementScoreFormData } from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';
import useFormSubmit from './hooks/useFormSubmit';
import useIsFeedbackDisabled from './hooks/useIsFeedbackDisabled';
import useShouldReSaveAfterAutoSave from './hooks/useShouldReSaveAfterAutoSave';

import './ReviewerScoringAndFeedback.scss';

export interface ReviewerScoringAndFeedbackProps {
  traroElement: TraroElement;
  rfpElementResponse: TraroElementResponse;
}

const ReviewerScoringAndFeedback = ({ traroElement, rfpElementResponse }: ReviewerScoringAndFeedbackProps) => {
  const data = useElementScoresWithResponses(traroElement, rfpElementResponse);
  const columns = useColumns();
  const formRef = useRef<FormikProps<ElementScoreFormData>>(null);
  const initialValues = useInitialValues(data, formRef);
  const schema = useSchema(data);
  const submit = useFormSubmit(rfpElementResponse);

  const isFeedbackDisabled = useIsFeedbackDisabled(rfpElementResponse);
  const shouldReSaveAfterAutoSave = useShouldReSaveAfterAutoSave();

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validationSchema={schema}
      validateOnMount
      enableReinitialize
      innerRef={formRef}
    >
      <Form className="enkrateia-reviewer-scoring-and-feedback">
        <AutoSaveWatcher
          allowInvalidSubmission
          shouldReSaveAfterAutoSave={shouldReSaveAfterAutoSave}
          disabled={isFeedbackDisabled}
        >
          <AutoSaveWatcher.Indicator />
          <Table variant="no-border" data={data} columns={columns} enableSorting={false} />
        </AutoSaveWatcher>
      </Form>
    </Formik>
  );
};

export default ReviewerScoringAndFeedback;
