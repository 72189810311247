import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { ElementScoreComponentWithResponse } from 'hooks/useElementScoresWithResponses/useElementScoresWithResponses';

import ResponseCell from '../components/ResponseCell/ResponseCell';

const useColumns = (): ColumnDef<ElementScoreComponentWithResponse, any>[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columnHelper = createColumnHelper<ElementScoreComponentWithResponse>();

    return [
      columnHelper.accessor('elementScoreComponent.display_order', {
        header: t('pages.traroElement.scoringAndFeedback.headers.no'),
        size: 10,
      }),
      columnHelper.accessor('elementScoreComponent.evaluation_criteria', {
        header: t('pages.traroElement.scoringAndFeedback.headers.evalCriteria'),
        size: 20,
      }),
      columnHelper.accessor('elementScoreComponent.evaluation_question', {
        header: t('pages.traroElement.scoringAndFeedback.headers.investigativeQuestion'),
        size: 500,
        cell: ResponseCell,
      }),
    ];
  }, [t]);
};

export default useColumns;
