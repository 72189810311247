import React, { FC, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'store';
import ArchElementSpecific from 'types/ArchElementSpecific';
import ArchElementsMap from 'types/ArchElementsMap';
import ArchElementGeneric from 'types/ArchElementGeneric';
import Note from 'components/Note/Note';
import QuestionStatusButton from 'components/QuestionStatusButton/QuestionStatusButton';
import CommonTransComponents from 'providers/i18n/CommonTransComponents';

import { useArchContextForElement } from '../../context/ArchContext';

import './SpecificElementDescription.scss';

export interface SpecificElementDescriptionProps {
  archElementSpecific: ArchElementSpecific;
}

const getTranslationKey = (archElementSpecific: ArchElementSpecific) => {
  switch (ArchElementsMap[archElementSpecific]) {
    case ArchElementGeneric.KEYSTONE:
      return 'pages.projectOverview.archFramework.elements.keystone';
    case ArchElementGeneric.SPRINGER:
      return 'pages.projectOverview.archFramework.elements.springer';
    case ArchElementGeneric.VOUSSOIR:
      return 'pages.projectOverview.archFramework.elements.voussoir';
    case ArchElementGeneric.BACKGROUND:
      return 'pages.projectOverview.archFramework.elements.background';
    default:
      throw new Error(`Invalid arch element: ${archElementSpecific}!`);
  }
};

const SpecificElementDescription: FC<SpecificElementDescriptionProps> = ({ archElementSpecific }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { response, element } = useArchContextForElement(archElementSpecific);
  const rfpId = useSelector((store) => store.rfp.data.id);
  const rfpResponseId = useSelector((store) => store.rfpResponse.data.id);

  const handleStatusClick = useCallback(
    () => navigate(`/rfp/${rfpId}/rfp-response/${rfpResponseId}/rfp-element-response/${element.arch_element_specific}`),
    [navigate, rfpResponseId, rfpId, element],
  );

  return (
    <div className="enkrateia-specific-element-description">
      <div className="main">
        <h2>{element.element_name}</h2>
        <ReactMarkdown linkTarget="_blank">{element.context0}</ReactMarkdown>
      </div>
      <div className="side-note">
        <QuestionStatusButton status={response.question_status} onClick={handleStatusClick} />
        <Note>
          <span>
            <Trans t={t} components={CommonTransComponents} i18nKey={getTranslationKey(archElementSpecific)} />
          </span>
        </Note>
      </div>
    </div>
  );
};

export default SpecificElementDescription;
