import React, { FC } from 'react';

import Header from 'components/Header/Header';

import ProjectInformation from './components/ProjectInformation/ProjectInformation';
import ArchElementOverview from './components/ArchElementOverview/ArchElementOverview';
import useElementsWithResponses from './hooks/useElementsWithResponses';

import './ProjectCondensedOverviewPage.scss';

export interface DashboardPageProps {}

const ProjectCondensedOverviewPage: FC<DashboardPageProps> = () => {
  const elementsWithResponses = useElementsWithResponses();

  return (
    <div className="enkrateia-project-condensed-overview-page">
      <Header />
      <div className="content">
        <ProjectInformation />
        {elementsWithResponses.map(({ element, response }) => (
          <ArchElementOverview key={response.id} traroElement={element} traroElementResponse={response} />
        ))}
      </div>
    </div>
  );
};

export default ProjectCondensedOverviewPage;
