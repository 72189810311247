import { useSelector } from 'store';
import { useMemo } from 'react';
import { sortBy } from 'lodash';

import TraroElement from 'types/api/TraroElement';
import TraroElementResponse from 'types/api/TraroElementResponse';

export interface ElementWithResponse {
  element: TraroElement;
  response: TraroElementResponse;
}

const useElementsWithResponses = (): ElementWithResponse[] => {
  const rfp = useSelector((store) => store.rfp.data);
  const rfpResponse = useSelector((store) => store.rfpResponse.data);

  return useMemo(
    () =>
      sortBy(
        rfpResponse.traro_element_response_list.map((response) => ({
          element: rfp.traro_element_list.find((element) => element.id === response.traro_element) as TraroElement,
          response,
        })),
        'element.element_number',
      ),
    [rfp, rfpResponse],
  );
};

export default useElementsWithResponses;
