import { useQuery } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import SettingsDto from 'types/api/SettingsDto';

export const fetchSettings = async (): Promise<SettingsDto> => {
  try {
    return (await API.get<SettingsDto>(`/v2/settings/`)).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = () => ['settings/settings'];

const useSettings = () => useQuery(getQueryKey(), fetchSettings);

useSettings.getQueryKey = getQueryKey;
useSettings.queries = {
  fetchSettings,
} as const;

export default useSettings;
