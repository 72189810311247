import React from 'react';

export interface RawHtmlCellProps {
  getValue: () => string;
}

const RawHTMLCell = ({ getValue }: RawHtmlCellProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return <div dangerouslySetInnerHTML={{ __html: getValue() }} />;
};

export default RawHTMLCell;
