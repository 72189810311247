import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';

import useDaysBefore from 'hooks/useDaysBefore';
import ProjectInfoPanel from 'components/ProjectInfoPanel/ProjectInfoPanel';
import EditableProjectInfoPanel from 'components/EditableProjectInfoPanel/EditableProjectInfoPanel';
import DatePickerInput from 'components/forms/raw/DatePickerInput';

export interface DaysLeftProps {
  closeDate: string | null;
  label: string;
  disabled?: boolean;
  onSubmit?: (value: DateTime | null) => Promise<void>;
}

const DaysLeft: FC<DaysLeftProps> = ({ closeDate, disabled, label, onSubmit = () => Promise.resolve() }) => {
  const { t } = useTranslation();
  const daysLeft = useDaysBefore(closeDate);

  return (
    <div className="days-left">
      <EditableProjectInfoPanel
        onSubmit={onSubmit}
        variant="kliparo"
        className="close-date"
        label={label}
        value={isEmpty(closeDate) || closeDate === null ? null : DateTime.fromISO(closeDate)}
        InputComponent={(props) => <DatePickerInput {...props} clearable variant="raw" />}
        areValuesEqual={(a, b) => a?.toISO() === b?.toISO()}
        disabled={disabled}
      />
      <ProjectInfoPanel
        className="days-left-count"
        label={t('pages.projectOverview.sidePanel.daysRemaining')}
        value={daysLeft}
        withBorder
      />
    </div>
  );
};

export default DaysLeft;
