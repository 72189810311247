import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CommonTransComponents from 'providers/i18n/CommonTransComponents';
import Button from 'components/Button/Button';

import useCookieBanner from './hooks/useCookieBanner';

import './CookieBanner.scss';

export interface CookieBannerProps {}

const CookieBanner: FC<CookieBannerProps> = () => {
  const { t } = useTranslation();
  const { isVisible, onAccept } = useCookieBanner();

  if (!isVisible) {
    return null;
  }

  return (
    <div className="enkrateia-cookie-banner">
      <div className="enkrateia-cookie-banner-content">
        <h2>{t('common.cookies.title')}</h2>
        <div className="terms">
          <Trans t={t} i18nKey={'common.cookies.text'} components={CommonTransComponents} />
        </div>
        <div className="buttons">
          <Button variant="primary" onClick={onAccept}>
            {t('common.cookies.accept')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
