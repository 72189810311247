import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

import { ElementScoreComponentWithResponse } from 'hooks/useElementScoresWithResponses/useElementScoresWithResponses';

const useSchema = (data: ElementScoreComponentWithResponse[]) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const shape: Record<number, yup.BaseSchema> = {};

    data.forEach((entry) => {
      shape[entry.elementScoreComponent.id] = yup.object().shape({
        responseScore: yup
          .number()
          .min(0, t('errors.minNumber', { count: 0 }))
          .max(
            entry.elementScoreComponent.max_points,
            t('errors.maxNumber', { count: entry.elementScoreComponent.max_points }),
          )
          .nullable(),
        responseFeedback: yup.mixed().nullable(),
      });
    });

    return yup.object().shape(shape);
  }, [data, t]);
};

export default useSchema;
