import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import 'react-day-picker/dist/style.css';

import useRequiredId from 'hooks/useRequiredId';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';
import { HelpIcon, TimesIcon } from 'components/SvgIcons';
import PopperWithFocusTrap from 'components/PopperWIthFocusTrap/PopperWithFocusTrap';
import useFormattedDate from 'hooks/useFormattedDate';

import './AdvancedDateRangePickerInput.scss';
type Variant = 'standard' | 'filled';

export interface DateRangeValue {
  from: DateTime | null;
  to: DateTime | null;
}

export interface AdvancedDateRangePickerInputProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' | 'onChange'> {
  label?: string;
  error?: string | ReactNode;
  hint?: string | ReactNode;
  touched?: boolean;
  fullWidth?: boolean;
  variant?: Variant;
  required?: boolean;
  value: DateRangeValue | null;
  noLabel?: boolean;
  onChange: (value: DateRangeValue | null) => void;
  clearable?: boolean;
}

const AdvancedDateRangePickerInput = forwardRef<HTMLInputElement, AdvancedDateRangePickerInputProps>(
  (
    {
      id,
      label,
      error,
      touched = false,
      required = false,
      fullWidth = false,
      className = '',
      type = 'text',
      variant = 'standard',
      hint,
      value,
      onChange,
      noLabel,
      clearable,
      onFocus: originalOnFocus,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const localRef = useRef<HTMLButtonElement>(null);
    const reOpenBlocker = useRef(false);

    const [open, setOpen] = useState(false);
    const ensuredId = useRequiredId(id);
    const errorId = `${ensuredId}-error`;

    const hasError = Boolean(error && error !== '' && touched);

    const handleDialogClose = useCallback(() => {
      reOpenBlocker.current = true;
      setOpen(false);

      setTimeout(() => {
        reOpenBlocker.current = false;
      }, 100);
    }, [reOpenBlocker]);

    const handleDialogOpen = useCallback(() => {
      if (!reOpenBlocker.current) {
        setOpen(true);
      }
    }, [reOpenBlocker]);

    const onSelect = useCallback(
      (range: DateRange) => {
        if (!range) {
          onChange(null);
        } else {
          onChange({
            from: range.from !== undefined ? DateTime.fromJSDate(range.from) : null,
            to: range.to !== undefined ? DateTime.fromJSDate(range.to) : null,
          });
        }
        // handleDialogClose();
      },
      [onChange, handleDialogClose],
    );
    const formattedFrom = useFormattedDate(value?.from?.toISODate() ?? '');
    const formattedTo = useFormattedDate(value?.to?.toISODate() ?? '');

    const valueString = useMemo(() => {
      if (formattedFrom !== '-' && formattedTo !== '-') {
        if (formattedFrom !== formattedTo) {
          return t('dateRangePicker.between', { formattedFrom, formattedTo });
        }

        return formattedFrom;
      }

      if (formattedTo !== '-') {
        return t('dateRangePicker.before', { formattedTo });
      }

      if (formattedFrom !== '-') {
        return t('dateRangePicker.after', { formattedFrom });
      }

      return `-`;
    }, [formattedFrom, formattedTo, t]);

    const onFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        handleDialogOpen();

        if (originalOnFocus) {
          originalOnFocus(event);
        }
      },
      [originalOnFocus, handleDialogOpen],
    );

    const [popperReferenceElement, setPopperReferenceElement] = useState<HTMLDivElement | null>(null);

    return (
      <div
        className={`enkrateia-date-range-picker-input ${className} variant-${variant} ${fullWidth ? 'full-width' : ''}`}
        ref={setPopperReferenceElement}
      >
        {!noLabel && (
          <label htmlFor={ensuredId}>
            {label}
            {required && '*'}
            {hint && (
              <Tooltip tooltip={hint}>
                <HelpIcon color="#05445E" size={25} />
              </Tooltip>
            )}
          </label>
        )}
        <div>
          <input
            {...props}
            value={valueString}
            readOnly
            onFocus={onFocus}
            onClick={handleDialogOpen}
            aria-invalid={hasError}
            aria-errormessage={errorId}
            type={type}
            id={ensuredId}
            ref={ref}
          />
          {hasError && (
            <span id={errorId} className="error-label">
              {error}
            </span>
          )}
          {clearable && (
            <button className="clear-icon" onClick={() => onChange(null)}>
              <TimesIcon color="#E4E4E4" size={12} />
            </button>
          )}
        </div>
        <PopperWithFocusTrap
          open={open}
          referenceElement={popperReferenceElement}
          popperElementClassName="date-picker"
          focusTrapOptions={{
            initialFocus: false,
            onDeactivate: handleDialogClose,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            fallbackFocus: localRef.current ?? undefined,
          }}
        >
          <div className="pickers">
            <div className="pickers-inner">
              <h3>{t('dateRangePicker.from')}</h3>
              <h3>{t('dateRangePicker.to')}</h3>
              <DayPicker
                initialFocus={open}
                mode="single"
                showOutsideDays
                selected={value?.from?.toJSDate()}
                onSelect={(from) => onSelect({ from, to: value?.to?.toJSDate() })}
              />
              <DayPicker
                initialFocus={open}
                mode="single"
                showOutsideDays
                selected={value?.to?.toJSDate()}
                onSelect={(to) => onSelect({ from: value?.from?.toJSDate(), to })}
              />
            </div>
            <div className="buttons-container">
              <Button variant="primary" onClick={handleDialogClose}>
                {t('dateRangePicker.ok')}
              </Button>
              <Button onClick={() => onSelect({ from: undefined, to: undefined })}>{t('dateRangePicker.clear')}</Button>
            </div>
          </div>
        </PopperWithFocusTrap>
      </div>
    );
  },
);

export default AdvancedDateRangePickerInput;
