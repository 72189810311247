import React from 'react';
import { useSettings } from 'api/settings';
import SettingsDto from 'types/api/SettingsDto';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';

const SystemSettingsContext = React.createContext<SettingsDto | null | undefined>(null);

export interface SystemSettingsProviderProps {
  children: React.ReactNode;
}

const SystemSettingsProvider = ({ children }: SystemSettingsProviderProps) => {
  const { isLoading, data } = useSettings();

  if (isLoading) {
    return <LoaderOverlay open />;
  }

  if (!data) {
    throw new Error('Settings could not be fetched!');
  }

  return <SystemSettingsContext.Provider value={data}>{children}</SystemSettingsContext.Provider>;
};

export const useSystemSettings = () => {
  const context = React.useContext(SystemSettingsContext);

  if (!context) {
    throw new Error('useSystemSettings must be used within a SystemSettingsProvider');
  }

  return context;
};

export default SystemSettingsProvider;
