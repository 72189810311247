import { useSystemSettings } from 'providers/SystemSettingsProvider';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './Footer.scss';

export interface FooterProps {
  noContactUs?: boolean;
}

const Footer: FC<FooterProps> = ({ noContactUs }) => {
  const { t } = useTranslation();
  const { contact_email } = useSystemSettings();

  return (
    <footer className="enkrateia-footer">
      <a href={t('common.footer.links.privacy.url')} target="_blank" rel="noreferrer">
        {t('common.footer.links.privacy.title')}
      </a>
      <span>|</span>
      <a href={t('common.footer.links.terms.url')} target="_blank" rel="noreferrer">
        {t('common.footer.links.terms.title')}
      </a>
      {!noContactUs && (
        <>
          <span>|</span>
          <a
            href={t('common.footer.links.contact.url', { contactEmail: contact_email })}
            target="_blank"
            rel="noreferrer"
          >
            {t('common.footer.links.contact.title', { contactEmail: contact_email })}
          </a>
        </>
      )}
    </footer>
  );
};

export default Footer;
