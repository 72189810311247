import React, { ComponentType, MutableRefObject, useImperativeHandle, MouseEvent } from 'react';
import {
  RowData,
  TableOptions,
  flexRender,
  useReactTable,
  getCoreRowModel as defaultGetCoreRowModel,
  getFilteredRowModel as defaultGetFilteredRowModel,
  getSortedRowModel as defaultSortedRowModel,
  getExpandedRowModel as defaultExpandedRowModel,
  Row as TRow,
  Table as TTable,
} from '@tanstack/react-table';

import Loader from 'components/Loader/Loader';

import Filter from './components/Filter/Filter';
import Row from './components/Row/Row';

import './Table.scss';

export interface TableProps<TData extends RowData> extends Omit<TableOptions<TData>, 'getCoreRowModel' | 'data'> {
  getCoreRowModel?: TableOptions<TData>['getCoreRowModel'];
  onRowClick?: (row: TRow<TData>, event: MouseEvent) => void;
  getRowLink?: (row: TRow<TData>) => string | false;
  getRowClassName?: (row: TRow<TData>) => string;
  data: TData[] | undefined;
  ExpandedRowComponent?: ComponentType<{ row: TRow<TData> }>;
  innerRef?: MutableRefObject<TTable<TData>>;
  variant?: 'default' | 'no-border';
}

const defaultEmptyData: unknown[] = [];

const Table = <TData extends RowData>({
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getExpandedRowModel,

  onRowClick,
  getRowLink,
  enableFilters = false,
  getRowClassName,
  data,
  ExpandedRowComponent,
  innerRef,
  variant = 'default',
  ...props
}: TableProps<TData>) => {
  const rowModel = getCoreRowModel ?? defaultGetCoreRowModel();
  const filteredRowModel = getFilteredRowModel ?? defaultGetFilteredRowModel();
  const sortedRowModel = getSortedRowModel ?? defaultSortedRowModel();
  const expandedRowModel = getExpandedRowModel ?? defaultExpandedRowModel();

  const table = useReactTable({
    getCoreRowModel: rowModel,
    getFilteredRowModel: filteredRowModel,
    getSortedRowModel: sortedRowModel,
    getExpandedRowModel: expandedRowModel,
    enableFilters,
    data: data ?? (defaultEmptyData as TData[]),
    ...props,
  });

  useImperativeHandle(innerRef, () => table);

  return (
    <table className={`enkrateia-table variant-${variant} ${onRowClick ? 'row-clickable' : ''}`}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) =>
              header.isPlaceholder ? (
                <th key={header.id}></th>
              ) : (
                <th key={header.id}>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                  <div
                    {...{
                      className: header.column.getCanSort() ? 'cursor-pointer select-none sortable-column' : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <span className="sort-indicator"> ▲</span>,
                      desc: <span className="sort-indicator"> ▼</span>,
                    }[header.column.getIsSorted() as string] ?? <span className="sort-indicator unsorted"> ▼</span>}
                  </div>
                  {header.column.getCanFilter() ? <Filter column={header.column} table={table} /> : null}
                </th>
              ),
            )}
          </tr>
        ))}
      </thead>
      <tbody>
        {data === undefined ? (
          <tr>
            <td colSpan={table.getVisibleFlatColumns().length} align="center" className="loader-row">
              <Loader />
            </td>
          </tr>
        ) : (
          table
            .getRowModel()
            .rows.map((row) => (
              <Row
                key={row.id}
                row={row}
                onRowClick={onRowClick}
                getRowLink={getRowLink}
                getRowClassName={getRowClassName}
                ExpandedRowComponent={ExpandedRowComponent}
              />
            ))
        )}
      </tbody>
      <tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </table>
  );
};

export default Table;
