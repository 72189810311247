import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Note from 'components/Note/Note';
import CommonTransComponents from 'providers/i18n/CommonTransComponents';

import './NoElementSelectedPlaceholder.scss';

export interface NoElementSelectedPlaceholderProps {}

const NoElementSelectedPlaceholder: FC<NoElementSelectedPlaceholderProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="enkrateia-no-element-selected-placeholder">
      <div className="main">
        <h2>{t('pages.projectOverview.archFramework.title')}</h2>
        <p>{t('pages.projectOverview.archFramework.description')}</p>

        <div>
          <h3>{t('pages.projectOverview.archFramework.step1.title')}</h3>
          <Trans
            t={t}
            i18nKey="pages.projectOverview.archFramework.step1.description"
            components={CommonTransComponents}
          />
        </div>

        <div>
          <h3>{t('pages.projectOverview.archFramework.step2.title')}</h3>
          <Trans
            t={t}
            i18nKey="pages.projectOverview.archFramework.step2.description"
            components={CommonTransComponents}
          />
        </div>

        <div>
          <h3>{t('pages.projectOverview.archFramework.step3.title')}</h3>
          <Trans
            t={t}
            i18nKey="pages.projectOverview.archFramework.step3.description"
            components={CommonTransComponents}
          />
        </div>
      </div>
      <Note>
        <p>{t('pages.projectOverview.archFramework.sideNote.title')}</p>
      </Note>
    </div>
  );
};

export default NoElementSelectedPlaceholder;
