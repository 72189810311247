import React from 'react';
import TraroElement from 'types/api/TraroElement';
import TraroElementResponse from 'types/api/TraroElementResponse';

import './ProponentScoringAndFeedback.scss';

export interface ProponentScoringAndFeedbackProps {
  traroElement: TraroElement;
  rfpElementResponse: TraroElementResponse;
}

const ProponentScoringAndFeedback = ({ rfpElementResponse }: ProponentScoringAndFeedbackProps) => {
  return (
    <div className="enkrateia-proponent-scoring-and-feedback">
      <h2 className="title">Reviewer feedback</h2>
      <div className="feedback">
        {rfpElementResponse.traro_element_score
          .filter((score) => !!score.element_feedback)
          .map((score) => (
            // eslint-disable-next-line @typescript-eslint/naming-convention
            <p key={score.element_score_component} dangerouslySetInnerHTML={{ __html: score.element_feedback }} />
          ))}
      </div>
    </div>
  );
};

export default ProponentScoringAndFeedback;
