/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import { HelpIcon } from 'components/SvgIcons';
import ReviewRatingBulb from 'components/ReviewRatingBulb/ReviewRatingBulb';
import Tooltip from 'components/Tooltip/Tooltip';

import './RatingHelp.scss';

const RatingHelp = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Tooltip tooltip={t('common.clickForDetails')}>
        <button onClick={() => setOpen(true)}>
          <HelpIcon size={24} color="#05445E" />
        </button>
      </Tooltip>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)} variant="kliparo" title={t('ratingHelp.title')}>
        <div className="enkrateia-rating-help-content">
          <ReviewRatingBulb.RawBulb
            variant="with-label"
            scoringRubric={{ description: t('rating.notReviewed'), highlight_color: 'Grey' }}
          />
          <span>{t('ratingHelp.notReviewed')}</span>
          <ReviewRatingBulb.RawBulb
            variant="with-label"
            scoringRubric={{ description: t('rating.inCompliance'), highlight_color: 'Green' }}
          />
          <span>{t('ratingHelp.inCompliance')}</span>
          <ReviewRatingBulb.RawBulb
            variant="with-label"
            scoringRubric={{ description: t('rating.minorChange'), highlight_color: 'Yellow' }}
          />
          <span>{t('ratingHelp.minorChange')}</span>
          <ReviewRatingBulb.RawBulb
            variant="with-label"
            scoringRubric={{ description: t('rating.open'), highlight_color: 'Yellow' }}
          />
          <span>{t('ratingHelp.open')}</span>
          <ReviewRatingBulb.RawBulb
            variant="with-label"
            scoringRubric={{ description: t('rating.remedial'), highlight_color: 'Red' }}
          />
          <span>{t('ratingHelp.remedial')}</span>
        </div>
      </Modal>
    </div>
  );
};

export default RatingHelp;
