import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

const useKeystoneCheckedCirclePositioner = (trigger: unknown) => {
  const circleRef = useRef<SVGSVGElement>(null);
  const textRef = useRef<SVGTextElement>(null);

  const handleResize = useCallback(() => {
    if (!circleRef.current || !textRef.current) {
      return;
    }

    const { x } = textRef.current.getBBox();

    circleRef.current.setAttribute('x', `${x - 30}`);
  }, [circleRef, textRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useLayoutEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 300);
  }, [handleResize, trigger]);

  return [circleRef, textRef] as const;
};

export default useKeystoneCheckedCirclePositioner;
