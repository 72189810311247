import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, ReactNode } from 'react';
import './Button.scss';

export type ButtonVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'secondary-filled'
  | 'danger'
  | 'tertiary'
  | 'tertiary-thin'
  | 'tertiary-filled'
  | 'grey'
  | 'grey-filled'
  | 'black-filled'
  | 'black'
  | 'light-grey-filled'
  | 'green-filled'
  | 'dark-green-filled'
  | 'link';

export interface ButtonProps
  extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'ref'> {
  children: ReactNode | ReactNode[];
  variant?: ButtonVariant;
  fullWidth?: boolean;
  readonly?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, readonly, className = '', variant = 'default', type = 'button', fullWidth = false, ...props }, ref) => (
    <button
      {...props}
      ref={ref}
      type={type}
      className={`enkrateia-button variant-${variant} ${fullWidth ? 'full-width' : ''} ${
        readonly ? 'readonly' : ''
      } ${className}`}
    >
      {children}
    </button>
  ),
);

export default Button;
