import React, { useCallback, useMemo } from 'react';
import { UnprivilegedEditor } from 'react-quill';

import useFormikConnection, { TouchTrigger, WithoutInjectedProps } from '../hooks/useFormikConnection';
import RichTextAreaInput, { RichTextAreaInputProps } from '../../raw/RichTextAreaInput';

export interface FormikTextInputProps extends WithoutInjectedProps<RichTextAreaInputProps, 'onChange'> {
  name: string;
  touchedTrigger?: TouchTrigger;
}

export interface FormikRichTextAreaValue {
  value: string;
  editor: UnprivilegedEditor | null;
}

const FormikRichTextAreaInput = ({ name, ...props }: FormikTextInputProps) => {
  // @ts-ignore
  const { setValue, error, ...other } = useFormikConnection<FormikRichTextAreaValue, HTMLInputElement>(name, props);
  const handleChange = useCallback(
    (value: string, a: unknown, b: unknown, editor: UnprivilegedEditor) => setValue({ value, editor }),
    [setValue],
  );

  const combinedErrors = useMemo(() => {
    const castError = error as { value?: string; editor?: string } | undefined;

    if (castError?.value) {
      return castError.value;
    }

    if (castError?.editor) {
      return castError.editor;
    }

    return error;
  }, [error]);

  return (
    // @ts-ignore
    <RichTextAreaInput
      {...props}
      {...other}
      error={combinedErrors}
      value={other.value?.value ?? ''}
      label={props.label}
      onChange={handleChange}
    />
  );
};

export default FormikRichTextAreaInput;
