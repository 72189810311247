import React, { FC } from 'react';
import ArchElementSpecific from 'types/ArchElementSpecific';
import { isCompleted, isUpdateRequired } from 'store/rfpResponse/helpers';

import CheckedCircle from '../CheckedCircle/CheckedCircle';
import ExclamationCircle from '../ExclamationCircle/ExclamationCircle';

import { useArchContextForElement } from '../../../../context/ArchContext';
import useElementSelection from '../../../../hooks/useElementSelection';

import useKeystoneCheckedCirclePositioner from './hooks/useKeystoneCheckedCirclePositioner';

import './KeystoneText.scss';

export interface KeystoneTextProps {
  x: number | string;
  y: number;
}

const KeystoneText: FC<KeystoneTextProps> = ({ x, y }) => {
  const { element, response } = useArchContextForElement(ArchElementSpecific.KEYSTONE);
  const [isSelected, select] = useElementSelection(ArchElementSpecific.KEYSTONE);
  const [circleRef, textRef] = useKeystoneCheckedCirclePositioner(response);

  return (
    <g className="enkrateia-arch-keystone-text" opacity={isSelected ? 1 : 0.3} onClick={select} cursor="pointer">
      {isCompleted(response) && <CheckedCircle ref={circleRef} x={x} y={y} />}
      {isUpdateRequired(response) && <ExclamationCircle ref={circleRef} x={x} y={y} />}
      <text x={x} y={y + 18} fill="#fff" textAnchor="middle" ref={textRef}>
        {element.element_name}
      </text>
    </g>
  );
};

export default KeystoneText;
