import { useAvailableRFPs, useRfp, useRfpResponse, useTicketConnectedElement } from 'api';
import TicketDto from 'types/api/TicketDto';

const useDataLoader = (ticket: TicketDto) => {
  const { data: ticketConnectedElement, isLoading: isTicketConnectedElementLoading } = useTicketConnectedElement(
    ticket.id,
    !!ticket.traro_element_response_id,
  );
  const { data: availableRFPs, isLoading: isAvailableRFPsLoading } = useAvailableRFPs(undefined);
  const { data: connectedRfpResponse, isLoading: isConnectedRfpResponseLoading } = useRfpResponse(
    ticketConnectedElement?.rfp_response_id,
  );
  const { data: rfp, isLoading: isRfpLoading } = useRfp(ticketConnectedElement?.rfp_id);

  return {
    ticketConnectedElement,
    connectedRfpResponse,
    availableRFPs,
    rfp,
    loading:
      isTicketConnectedElementLoading ||
      isAvailableRFPsLoading ||
      isConnectedRfpResponseLoading ||
      (!!ticketConnectedElement?.rfp_id && isRfpLoading),
  } as const;
};

export default useDataLoader;
