import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import TicketDto from 'types/api/TicketDto';
import { useTicketConnectedElement } from 'api';
import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import useLinkedElementUrl from 'hooks/useLinkedElementUrl/useLinkedElementUrl';

import { LinkedElementInputOption } from '../../hooks/useInitialValues';

import './LinkedElementField.scss';

interface LinkedElementFieldProps {
  ticket: TicketDto;
  selectableLinkedCriteria: LinkedElementInputOption[];
  disabled?: boolean;
}

const LinkedElementField = ({ selectableLinkedCriteria, ticket, disabled }: LinkedElementFieldProps) => {
  const { t } = useTranslation();
  const { data: ticketConnectedElement, isFetching } = useTicketConnectedElement(
    ticket.id,
    !!ticket.traro_element_response_id,
  );

  const linkedElementUrl = useLinkedElementUrl(ticketConnectedElement);

  if (!ticket.traro_element_response_id) {
    return null;
  }

  return (
    <div className="enkrateia-ticket-linked-criterion-field">
      <FormikSelectInput variant="filled" name="linkedElement" options={selectableLinkedCriteria} disabled={disabled} />
      {!!ticketConnectedElement && !isFetching && (
        <Link to={linkedElementUrl}>{t('common.tickets.openLinkedElement')}</Link>
      )}
      {isFetching && <Skeleton width={80} height={28} />}
    </div>
  );
};

export default LinkedElementField;
