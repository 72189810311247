import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Table as TTable } from '@tanstack/react-table';

import TicketSourceType from 'types/TicketSourceType';
import TicketDto from 'types/api/TicketDto';
import Table from 'components/Table/Table';
import TraroElementResponse from 'types/api/TraroElementResponse';
import CollapsibleSection from 'components/CollapsibleSection/CollapsibleSection';

import useColumns from './hooks/useColumns';
import useTicketsFromCategory from './hooks/useTicketsFromCategory';
import ExpandedTicketRow from './components/ExpandedTicketRow/ExpandedTicketRow';

import './TicketsCategory.scss';

export interface TicketsCategoryProps {
  category: TicketSourceType;
  tickets: TicketDto[];
  rfpElementResponse: TraroElementResponse;
}

const TicketsCategory: FC<TicketsCategoryProps> = ({ tickets, category, rfpElementResponse }) => {
  const columns = useColumns(rfpElementResponse);
  const { t } = useTranslation();
  const applicableTickets = useTicketsFromCategory(tickets, category);

  const tableRef = useRef<TTable<TicketDto>>({} as TTable<TicketDto>);

  useEffect(() => {
    // Open row if there is only one ticket
    if (applicableTickets.length === 1) {
      tableRef?.current.toggleAllRowsExpanded(true);
    }
  }, [applicableTickets]);

  if (applicableTickets.length === 0) {
    return null;
  }

  return (
    <CollapsibleSection
      className="enkrateia-ticket-category"
      defaultExpanded={tickets.length === 1 && applicableTickets.length === 1}
      title={`${t('common.tickets.category')} - ${t(`common.tickets.sources.${category}`)} (${
        applicableTickets.length
      })`}
    >
      <Table
        data={applicableTickets}
        columns={columns}
        onRowClick={(row, event) => {
          // Do not close on select click
          if ((event.target as HTMLElement)?.className?.includes('react-select')) {
            return;
          }

          if (!row.getIsExpanded()) {
            tableRef.current?.toggleAllRowsExpanded(false);
          }

          row.toggleExpanded();
        }}
        innerRef={tableRef}
        ExpandedRowComponent={ExpandedTicketRow}
      />
    </CollapsibleSection>
  );
};

export default TicketsCategory;
