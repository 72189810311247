import React, { FC, MutableRefObject, ReactElement, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';

import TabsContext from './context/TabsContext';
import Tab, { TabProps } from './components/Tab/Tab';
import TabName from './components/TabName/TabName';

import './Tabs.scss';

export interface TabsRef {
  setActiveKey: (key: TabProps['tabKey']) => void;
}

export interface TabsProps {
  /**
   * Children elements (they must be instances of ReactElement with TabProps props)
   */
  children: ReactElement<TabProps>[];

  /**
   * Which tab should be visible initially (defaults to first tab)
   */
  initialTab?: string;

  /**
   * Ref for imperative handling of the Tabs element
   */
  innerRef?: MutableRefObject<TabsRef>;

  /**
   * Pre-tab node rendered inline with tabs after them
   */
  preTabNamesNode?: ReactElement;

  /**
   * Post-tab node rendered inline with tabs after them
   */
  postTabNamesNode?: ReactElement;

  /**
   * Callback that is called when active tab changes
   *
   * @param tab
   */
  onActiveTabChange?: (tab: string) => void;
}

/**
 * Utility component for rendering tabs that look exactly as on the mockups.
 *
 * The header tab names are cleverly extracted from this components children so that they don't have to be
 * explicitly specified.
 *
 * The Tabs.Tab components communicate with parent using Context to avoid altering the way they are rendered
 * (using React.clone for example). Thanks to this approach we can write tab definitions in clean and elegant way.
 *
 * See `Tabs.stories.tsx` for example usage.
 */
const Tabs: FC<TabsProps> & { Tab: typeof Tab } = ({
  children,
  initialTab,
  innerRef,
  preTabNamesNode = null,
  postTabNamesNode = null,
  onActiveTabChange,
}) => {
  const [activeKey, setActiveKeyValue] = useState(initialTab ?? children[0].props.tabKey);
  const setActiveKey = useCallback(
    (key: string) => {
      setActiveKeyValue(key);
      onActiveTabChange?.(key);
    },
    [onActiveTabChange],
  );

  useImperativeHandle(
    innerRef,
    () => ({
      setActiveKey,
    }),
    [setActiveKey],
  );

  const tabsState = useMemo(() => ({ activeKey, setActiveKey }), [activeKey, setActiveKey]);

  return (
    <TabsContext.Provider value={tabsState}>
      <div className="enkrateia-tabs">
        <div className="enkrateia-tabs-header">
          {preTabNamesNode}
          {children.map(({ props: { tabKey, label } }) => (
            <TabName key={tabKey} active={activeKey === tabKey} onClick={() => setActiveKey(tabKey)} label={label} />
          ))}
          {postTabNamesNode}
        </div>
        <TransitionGroup className="enkrateia-tabs-content">{children}</TransitionGroup>
      </div>
    </TabsContext.Provider>
  );
};

Tabs.Tab = Tab;

export default Tabs;
