import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';

export interface SuccessStepProps {
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}

const SuccessStep = ({ onClose }: SuccessStepProps) => {
  const { t } = useTranslation();

  return (
    <div className="success-step">
      <div className="submit-container">
        <Button variant="primary" onClick={onClose}>
          {t('pages.dashboard.createAssessment.ok')}
        </Button>
      </div>
    </div>
  );
};

export default SuccessStep;
