import ArchElementSpecific from './ArchElementSpecific';
import ArchElementGeneric from './ArchElementGeneric';

const ArchElementsMap: Record<ArchElementSpecific, ArchElementGeneric> = {
  [ArchElementSpecific.KEYSTONE]: ArchElementGeneric.KEYSTONE,
  [ArchElementSpecific.VOUSSOIR_LEFT_1]: ArchElementGeneric.VOUSSOIR,
  [ArchElementSpecific.VOUSSOIR_LEFT_2]: ArchElementGeneric.VOUSSOIR,
  [ArchElementSpecific.VOUSSOIR_RIGHT_1]: ArchElementGeneric.VOUSSOIR,
  [ArchElementSpecific.VOUSSOIR_RIGHT_2]: ArchElementGeneric.VOUSSOIR,
  [ArchElementSpecific.SPRINGER_LEFT]: ArchElementGeneric.SPRINGER,
  [ArchElementSpecific.SPRINGER_RIGHT]: ArchElementGeneric.SPRINGER,
  [ArchElementSpecific.BRICK_1]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BRICK_2]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BRICK_3]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BRICK_4]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BRICK_5]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BRICK_6]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BRICK_7]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BRICK_8]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BRICK_9]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BRICK_10]: ArchElementGeneric.BRICK,
  [ArchElementSpecific.BACKGROUND]: ArchElementGeneric.BACKGROUND,
};

export default ArchElementsMap;
