import { ReactNode, useEffect, useRef } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import useRequiredId from 'hooks/useRequiredId';
import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon } from 'components/SvgIcons';
import useForceRerender from 'hooks/useForceRerender';

import './RichTextAreaInput.scss';

type Variant = 'standard' | 'filled';

export interface RichTextAreaInputProps extends ReactQuillProps {
  label?: string;
  error?: string | ReactNode;
  hint?: string | ReactNode;
  touched?: boolean;
  fullWidth?: boolean;
  rows?: number;
  variant?: Variant;
  required?: boolean;
  disabled?: boolean;
  autoHeight?: boolean;
  softMaxCharacters?: number;
}

const RichTextAreaInput = ({
  id,
  label,
  error,
  touched = false,
  required = false,
  fullWidth = false,
  className = '',
  hint,
  rows,
  variant = 'standard',
  disabled = false,
  autoHeight = false,
  softMaxCharacters = 0,
  ...props
}: RichTextAreaInputProps) => {
  const ensuredId = useRequiredId(id);
  const errorId = `${ensuredId}-error`;
  const quillRef = useRef<ReactQuill>(null);

  const hasError = Boolean(error && error !== '' && touched);
  const hasCharacterCount = softMaxCharacters > 0;
  const characterCount = quillRef.current?.getEditor().getText().trim().length ?? 0;

  const forceRerender = useForceRerender();

  useEffect(() => {
    // On mount ensure that character count is correct
    if (hasCharacterCount) {
      forceRerender();
    }
    // No deps ON PURPOSE!
  }, []);

  return (
    <div
      className={`enkrateia-rich-textarea-input ${className} variant-${variant} ${fullWidth ? 'full-width' : ''} ${
        autoHeight ? 'auto-height' : ''
      } ${hasError ? 'error' : ''}`}
    >
      {label && (
        <label htmlFor={ensuredId}>
          {label}
          {required && '*'}
          {hint && (
            <Tooltip tooltip={hint}>
              <HelpIcon color="#05445E" size={25} />
            </Tooltip>
          )}
        </label>
      )}
      <div
        className={`quill-container ${disabled ? 'disabled' : ''}`}
        style={{ minHeight: rows ? `${rows * 30}px` : 'auto' }}
      >
        <ReactQuill
          {...props}
          ref={quillRef}
          readOnly={disabled}
          theme="snow"
          aria-invalid={hasError}
          aria-errormessage={errorId}
          id={ensuredId}
        />
      </div>
      {hasError && (
        <span id={errorId} className="error-label">
          {error}
        </span>
      )}
      {hasCharacterCount && (
        <span className="character-count">
          {characterCount}/{softMaxCharacters}
        </span>
      )}
    </div>
  );
};

export default RichTextAreaInput;
