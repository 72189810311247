import React from 'react';
import { useSelector } from 'store';
import { useTranslation } from 'react-i18next';

import CollapsibleSection from 'components/CollapsibleSection/CollapsibleSection';
import RFPResponseStatusIndicator from 'components/RFPResponseStatusIndicator/RFPResponseStatusIndicator';
import MailtoIconLink from 'components/MailtoIconLink/MailtoIconLink';

import './ProjectInformation.scss';

const ProjectInformation = () => {
  const { t } = useTranslation();
  const rfp = useSelector((store) => store.rfp.data);
  const rfpResponse = useSelector((store) => store.rfpResponse.data);

  return (
    <div className="enkrateia-condensed-project-information">
      <CollapsibleSection title={t('pages.projectOverview.mainPanel.condensedView.projectInformation')} defaultExpanded>
        <dl>
          <dt>{t('pages.projectOverview.mainPanel.condensedView.projectName')}</dt>
          <dd>{rfpResponse.project_name}</dd>
          <dt>{t('pages.projectOverview.mainPanel.condensedView.organization')}</dt>
          <dd>{rfpResponse.organization.name}</dd>
          <dt>{t('pages.projectOverview.mainPanel.condensedView.primaryContact')}</dt>
          <dd>
            {rfpResponse.point_of_contact.first_name} {rfpResponse.point_of_contact.last_name}
            <MailtoIconLink email={rfpResponse.point_of_contact.email} color="#404040" size={25} />
          </dd>
          <dt>{t('pages.projectOverview.mainPanel.condensedView.rfpName')}</dt>
          <dd>{rfp.name}</dd>
          <dt>{t('pages.projectOverview.mainPanel.condensedView.score')}</dt>
          <dd>
            {rfpResponse.rfp_response_score} / {rfp.max_score}
          </dd>
          <dt>{t('pages.projectOverview.mainPanel.condensedView.status')}</dt>
          <dd>
            <RFPResponseStatusIndicator status={rfpResponse.rfp_response_status} />
          </dd>
        </dl>
      </CollapsibleSection>
    </div>
  );
};

export default ProjectInformation;
