/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormikHelpers } from 'formik';

import { saveTicket } from 'services/ticket';
import { useRecentTickets, useRfpElementTickets, useTicket, useTicketConnectedElement, useTickets } from 'api';
import useUser from 'hooks/useUser';

import { TicketFormState } from './useInitialValues';

interface TicketCreationProps {
  values: TicketFormState;
  helpers: FormikHelpers<TicketFormState>;
}

const useTicketSubmit = () => {
  const queryClient = useQueryClient();
  const { isReviewer } = useUser();

  const { mutateAsync, isLoading, isSuccess } = useMutation(
    async ({ values }: TicketCreationProps) => {
      const data = {
        ...values.originalTicket,
        assigned_user: values.assignedTo.value,
        short_description: values.ticketDescription,
        due_date: values.dueDate.toISO(),
        ticket_status: values.ticketStatus,
        traro_element_response_id: values.linkedElement?.value || null,
        source: values.category?.value || null,
      };

      if (isReviewer) {
        data.severity_rating = values.severity?.value || null;
      }

      return saveTicket(data);
    },
    {
      onSuccess: async (data) => {
        for (const value of [
          useTicket.getQueryKey(data.ticket_list[0].id),
          useRfpElementTickets.getQueryKey(),
          useRecentTickets.getQueryKey(),
          useTickets.getQueryKey(),
          useTicketConnectedElement.getQueryKey(data.ticket_list[0].id),
        ]) {
          await queryClient.invalidateQueries({ queryKey: value });
          await queryClient.refetchQueries({ queryKey: value });
        }
      },
      onError: (error: Error, variables) => {
        variables.helpers.setFieldError('ticketDescription', error.message);
      },
    },
  );

  const saveForm = useCallback(
    (values: TicketFormState, helpers: FormikHelpers<TicketFormState>) => mutateAsync({ values, helpers }),
    [mutateAsync],
  );

  return [saveForm, isLoading, isSuccess] as const;
};

export default useTicketSubmit;
