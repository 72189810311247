import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import TicketWithConnection from 'types/TicketWithConnection';
import { formatDate } from 'hooks/useFormattedDate';
import { EllipsisTextCell, TicketIdCell, MarkdownHoverCell } from 'components/Table';
import { DateRangeValue } from 'components/forms/raw/AdvancedDateRangePickerInput';
import useUser from 'hooks/useUser';

import LinkedElementCell from '../components/LinkedElementCell/LinkedElementCell';
import TicketStatusFilter from '../components/TicketStatusFilter/TicketStatusFilter';
import TicketUserFilter from '../components/TicketUserFilter/TicketUserFilter';
import TicketDateFilter from '../components/TicketDateFilter/TicketDateFilter';
import TicketSeverityRatingFilter from '../components/TicketSeverityRatingFilter/TicketSeverityRatingFilter';
import TicketSourceFilter from '../components/TicketSourceFilter/TicketSourceFilter';

const useColumns = () => {
  const { t } = useTranslation();
  const { isReviewer } = useUser();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TicketWithConnection>();

    const columns: ColumnDef<TicketWithConnection, any>[] = [];

    columns.push(
      columnHelper.accessor('id', {
        header: t('common.tickets.ticketNumber'),
        cell: TicketIdCell,
        filterFn: 'includesString',
        sortingFn: 'alphanumeric',
        size: 40,
      }),
    );

    if (!isReviewer) {
      columns.push(
        columnHelper.accessor('short_description', {
          header: t('common.tickets.description'),
          filterFn: 'includesString',
          sortingFn: 'text',
          cell: EllipsisTextCell,
        }),
      );
    }

    columns.push(
      columnHelper.accessor('ticket_status', {
        header: t('common.tickets.status'),
        sortingFn: 'text',
        size: 40,
        meta: {
          CustomFilter: TicketStatusFilter,
        },
      }),
    );

    if (isReviewer) {
      columns.push(
        columnHelper.accessor('organization.name', {
          header: t('common.tickets.organization'),
        }),
        columnHelper.accessor('source', {
          header: t('common.tickets.category'),
          size: 40,
          meta: {
            CustomFilter: TicketSourceFilter,
          },
        }),
        columnHelper.accessor('severity_rating', {
          header: t('common.tickets.severityRating'),
          sortingFn: 'text',
          size: 40,
          meta: {
            CustomFilter: TicketSeverityRatingFilter,
          },
        }),
      );
    }

    columns.push(
      columnHelper.accessor((value) => `${value.assigned_first_name} ${value.assigned_last_name}`, {
        id: 'assigned',
        header: t('common.tickets.assignedTo'),
        sortingFn: (rowA, rowB) => {
          const dateA = DateTime.fromISO(rowA.original.due_date);
          const dateB = DateTime.fromISO(rowB.original.due_date);

          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0;
        },
        size: 40,
        filterFn: (value, unused, filterValue) => {
          if (filterValue === null || filterValue === undefined) return true;

          return String(value.original.assigned_user as never as number) === String(filterValue);
        },
        meta: {
          CustomFilter: TicketUserFilter,
        },
      }),
      columnHelper.accessor('due_date', {
        header: t('common.tickets.dueDate'),
        sortingFn: 'text',
        size: 40,
        cell: (cell) => formatDate(cell.getValue()),
        filterFn: (value, unused, filterValue: DateRangeValue | null) => {
          if (filterValue === null || filterValue === undefined) return true;

          const date = DateTime.fromISO(value.original.due_date);

          if (filterValue.from && filterValue.to) {
            return filterValue.from <= date && date <= filterValue.to;
          }

          if (filterValue.from) {
            return filterValue.from <= date;
          }

          if (filterValue.to) {
            return date <= filterValue.to;
          }

          return true;
        },
        meta: {
          CustomFilter: TicketDateFilter,
        },
      }),
    );

    if (isReviewer) {
      columns.push(
        columnHelper.accessor('short_description', {
          header: t('common.tickets.description'),
          filterFn: 'includesString',
          sortingFn: 'text',
          cell: MarkdownHoverCell,
        }),
      );
    }

    columns.push(
      columnHelper.accessor('connection.element_name', {
        header: t('common.tickets.linkedElement'),
        sortingFn: 'text',
        filterFn: 'includesString',
        cell: LinkedElementCell,
      }),
    );

    return columns;
  }, [t, isReviewer]);
};

export default useColumns;
