import React from 'react';

import { useSystemSettings } from '../SystemSettingsProvider';

import '../../styles/index.scss';

export interface StylesProviderProps {
  children: React.ReactNode;
}

const StylesProvider = ({ children }: StylesProviderProps) => {
  const { custom_styles } = useSystemSettings();

  return (
    <>
      {children}
      <style>{custom_styles}</style>
    </>
  );
};

export default StylesProvider;
