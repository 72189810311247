import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'store';
import { closeModal } from 'store/modals';
import Modal from 'components/Modal/Modal';
import QuestionStatusButton from 'components/QuestionStatusButton/QuestionStatusButton';
import QuestionStatus from 'types/QuestionStatus';

import './QuestionStatusesHelpModal.scss';

export interface ProjectSubmittedModalProps {}

const QuestionStatusesHelpModal: FC<ProjectSubmittedModalProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isOpen = useSelector((store) => store.modals.questionStatusesHelpModal.open);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal('questionStatusesHelpModal'));
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldFocusAfterRender
      className="enkrateia-question-statuses-help-modal"
    >
      <div className="title">
        <h2>{t('common.statusHelp.title')}</h2>
      </div>
      <div className="details">
        <dl>
          <dt>
            <QuestionStatusButton status={QuestionStatus.BEGIN} />
          </dt>
          <dd>{t('common.statusHelp.begin')}</dd>
          <dt>
            <QuestionStatusButton status={QuestionStatus.IN_PROGRESS} />
          </dt>
          <dd>{t('common.statusHelp.inProgress')}</dd>
          <dt>
            <QuestionStatusButton status={QuestionStatus.DONE} />
          </dt>
          <dd>{t('common.statusHelp.done')}</dd>
          <dt>
            <QuestionStatusButton status={QuestionStatus.INCOMPLETE} />
          </dt>
          <dd>{t('common.statusHelp.updateRequired')}</dd>
          <dt>
            <QuestionStatusButton status={QuestionStatus.REVIEW_ONLY} />
          </dt>
          <dd>{t('common.statusHelp.reviewOnly')}</dd>
          <dt>
            <QuestionStatusButton status={QuestionStatus.REVIEW_COMPLETE} />
          </dt>
          <dd>{t('common.statusHelp.reviewComplete')}</dd>
          <dt>
            <QuestionStatusButton status={QuestionStatus.UPDATE_REQUIRED} />
          </dt>
          <dd>{t('common.statusHelp.updateRequired')}</dd>
        </dl>
      </div>
    </Modal>
  );
};

export default QuestionStatusesHelpModal;
