import TicketConnectionDetailsDto from '../../types/api/TicketConnectionDetailsDto';

const useLinkedElementUrl = (details: TicketConnectionDetailsDto | undefined | null) => {
  if (!details) {
    return '';
  }

  if (details.rfp_type === 'traro') {
    return `/rfp/${details.rfp_id}/rfp-response/${details.rfp_response_id}/rfp-element-response/${details?.arch_element_specific}`;
  }

  return `/rfp/${details.rfp_id}/rfp-response/${details.rfp_response_id}/rfp-question-response/${details.section_number}/${details.question_number}`;
};

export default useLinkedElementUrl;
