import React from 'react';
import { Row } from '@tanstack/react-table';

import { useTicket } from 'api';
import TicketDto from 'types/api/TicketDto';
import TicketComments from 'components/TicketDetails/components/TicketComments/TicketComments';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';

export interface ExpandedTicketRowProps {
  row: Row<TicketDto>;
}

const ExpandedTicketRow = ({ row }: ExpandedTicketRowProps) => {
  const { data, isLoading } = useTicket(row.original.id);

  return (
    <div className="enkrateia-ticket-details">
      <LoaderOverlay open={isLoading} />
      {data && <TicketComments ticket={data} noHeader noAttachments />}
    </div>
  );
};

export default ExpandedTicketRow;
