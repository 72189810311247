import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';

import { useRfpResponse } from './index';

interface ScoringParam {
  element_score_component_id: number;
  response_score: number | '';
  response_feedback: string;
}

export type SaveScoringParams = Record<number, ScoringParam>;

const saveResponseScoring = async (elementResponseId: number, scoringParams: SaveScoringParams) => {
  try {
    return (await API.post(`/v2/resposne-element/${elementResponseId}/scoring/`, scoringParams)).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const useSaveResponseScoringMutation = (elementResponseId: number, rfpResponseId?: number) => {
  const queryClient = useQueryClient();

  return useMutation<void, FormSubmitError, SaveScoringParams>(
    (params) => saveResponseScoring(elementResponseId, params),
    {
      onSuccess: async () => {
        for (const value of [useRfpResponse.getQueryKey(rfpResponseId)]) {
          await queryClient.invalidateQueries({ queryKey: value });
        }
      },
    },
  );
};

useSaveResponseScoringMutation.queries = {
  saveResponseScoring,
} as const;

export default useSaveResponseScoringMutation;
