import React from 'react';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import { useTicket } from 'api';

import TicketForm from './components/TicketForm/TicketForm';
import TicketComments from './components/TicketComments/TicketComments';

import './TicketDetail.scss';

export interface TicketContentProps {
  ticketId: number | string;
}

const TicketDetails = ({ ticketId }: TicketContentProps) => {
  const { data, isLoading } = useTicket(ticketId);

  return (
    <div className="enkrateia-ticket-details">
      <LoaderOverlay open={isLoading} />
      {data && <TicketForm ticket={data} />}
      {data && <TicketComments ticket={data} />}
    </div>
  );
};

export default TicketDetails;
