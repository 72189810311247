import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import Button from 'components/Button/Button';
import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon } from 'components/SvgIcons';

import { TicketCommentFormState } from '../../hooks/useInitialValues';

import './TogglePublishFlag.scss';

const TogglePublishFlag = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<TicketCommentFormState>();

  const status = values.published ? 'enabled' : 'disabled';
  const buttonVariant = values.published ? 'tertiary-filled' : 'tertiary-thin';

  return (
    <div className="enkrateia-ticket-comment-toggle-publish-flag">
      <Button variant={buttonVariant} onClick={() => setFieldValue('published', !values.published)}>
        {t(`pages.ticketDetails.publishedComment.button.${status}`)}
      </Button>
      <Tooltip tooltip={t('pages.ticketDetails.publishedComment.help')} placement="top">
        <HelpIcon color="#05445E" size={25} />
      </Tooltip>
    </div>
  );
};

export default TogglePublishFlag;
