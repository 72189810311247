import { useMemo } from 'react';

import { getQuestionSectionForResponse } from 'store/rfpResponse/helpers';
import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionSection from 'types/api/QuestionSection';
import RFPResponseWithRFP from 'types/api/RFPResponseWithRFP';
import RFP from 'types/api/RFP';

import { LinkedElementInputOption } from './useInitialValues';

const useConnectedQuestionOptions = (
  connectedRfpResponse: RFPResponseWithRFP | null | undefined,
  rfp: RFP | null | undefined,
): LinkedElementInputOption[] =>
  useMemo(() => {
    if (!connectedRfpResponse || !rfp) {
      return [];
    }

    if (connectedRfpResponse.traro_rfp.rfp_type === 'traro') {
      return rfp.traro_element_list.map((element) => {
        const response = connectedRfpResponse.traro_element_response_list.find((r) => r.traro_element === element.id);

        return {
          label: element.element_name,
          value: response?.id as number,
          meta: {
            response,
            question: null,
          },
        } as LinkedElementInputOption;
      }) as LinkedElementInputOption[];
    }

    const sections = connectedRfpResponse.traro_rfp.kliparo_section_list;
    const responses = connectedRfpResponse.traro_element_response_list;

    const options = responses
      .map((response) => [response, getQuestionSectionForResponse(sections, response)])
      .filter(([, question]) => question !== null) as [TraroElementResponse, QuestionSection][];

    return options
      .reduce((acc, [response, question]) => {
        acc.push({
          label: question.traro_element.element_name,
          value: response.id as number,
          meta: {
            response,
            question,
          },
        });

        return acc;
      }, [] as LinkedElementInputOption[])
      .sort((a, b) => {
        if (typeof a.label === 'string' && typeof b.label === 'string') {
          return a.label.localeCompare(b.label);
        }

        return 0;
      });
  }, [connectedRfpResponse, rfp]);

export default useConnectedQuestionOptions;
