import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import TraroElementResponse from 'types/api/TraroElementResponse';

import TicketDto from 'types/api/TicketDto';
import { TicketIdCell, MarkdownHoverCell } from 'components/Table';
import AssignedToCell from '../components/AssignedToCell/AssignedToCell';

const useColumns = (rfpElementResponse: TraroElementResponse) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TicketDto>();

    return [
      columnHelper.accessor('id', {
        header: t('common.tickets.ticketNumber'),
        cell: TicketIdCell,
        filterFn: 'includesString',
        size: 40,
      }),
      columnHelper.accessor('short_description', {
        header: t('common.tickets.description'),
        filterFn: 'includesString',
        cell: MarkdownHoverCell,
      }),
      columnHelper.accessor('ticket_status', {
        header: t('common.tickets.status'),
        size: 40,
      }),
      columnHelper.accessor(() => rfpElementResponse, {
        id: 'assigned',
        header: t('common.tickets.assignedTo'),
        size: 80,
        cell: AssignedToCell,
      }),
    ];
  }, [t]);
};

export default useColumns;
