import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'store';
import RFPSubmitResponseStatus from 'types/RFPSubmitResponseStatus';
import { updateRfpResponseName } from 'store/rfpResponse';

import useFormattedDate, { DEFAULT_DATE_TIME_FORMAT_WITH_TIMEZONE } from 'hooks/useFormattedDate';
import useDaysBefore from 'hooks/useDaysBefore';
import useUser from 'hooks/useUser';
import ProjectInfoPanel from 'components/ProjectInfoPanel/ProjectInfoPanel';
import EditableProjectInfoPanel from 'components/EditableProjectInfoPanel/EditableProjectInfoPanel';
import TextAreaInput from 'components/forms/raw/TextAreaInput';

import './ProjectInfo.scss';
import MailtoIconLink from '../../../../../../components/MailtoIconLink/MailtoIconLink';

export interface ProjectInfoProps {}

const ProjectInfo: FC<ProjectInfoProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { close_date, response_close_date } = useSelector((store) => store.rfp.data);
  const rfpId = useSelector((store) => store.rfp.data.id);
  const { project_name, rfp_response_status, last_update, rfp_submit_response_status, organization, point_of_contact } =
    useSelector((store) => store.rfpResponse.data);
  const { isReviewer } = useUser();

  const closeDate = useFormattedDate(close_date);
  const responseCloseDate = useFormattedDate(response_close_date, DEFAULT_DATE_TIME_FORMAT_WITH_TIMEZONE);
  const lastUpdate = useFormattedDate(last_update);

  const days = useDaysBefore(response_close_date);

  const handleProjectNameChange = useCallback(
    async (newProjectName: string) => {
      await dispatch(updateRfpResponseName({ rfpId: rfpId, newProjectName })).unwrap();
    },
    [dispatch, rfpId],
  );

  return (
    <div className="enkrateia-project-info">
      <EditableProjectInfoPanel
        variant="traro"
        label={t('pages.projectOverview.sidePanel.name')}
        value={project_name ?? t('pages.projectOverview.sidePanel.pleaseEnterProjectName')}
        className="span-2-column"
        disabled={rfp_submit_response_status === RFPSubmitResponseStatus.NO_SUBMISSIONS_REMAINING || isReviewer}
        onSubmit={handleProjectNameChange}
        InputComponent={({ innerRef, ...props }) => <TextAreaInput {...props} ref={innerRef} rows={1} variant="raw" />}
        areValuesEqual={(a, b) => a.trim() === b.trim()}
      />
      <ProjectInfoPanel
        variant="traro"
        className="span-2-column primary-contact"
        label={t('pages.projectOverview.sidePanel.primaryContact')}
        value={
          <>
            {point_of_contact.first_name} {point_of_contact.last_name}
            {isReviewer && <MailtoIconLink email={point_of_contact.email} color="#ffffff" />}
          </>
        }
      />
      <ProjectInfoPanel
        variant="traro"
        label={t('pages.projectOverview.sidePanel.company')}
        value={organization.name}
      />
      <ProjectInfoPanel
        variant="traro"
        label={t('pages.projectOverview.sidePanel.rfpStatus')}
        value={rfp_response_status}
      />
      <ProjectInfoPanel
        variant="traro"
        className="span-2-column"
        label={t('pages.projectOverview.sidePanel.entryCloseDate')}
        value={responseCloseDate}
      />
      <ProjectInfoPanel
        variant="traro"
        label={t('pages.projectOverview.sidePanel.daysRemaining')}
        className="span-2-column half-width"
        value={days}
        withBorder
      />
      <ProjectInfoPanel variant="traro" label={t('pages.projectOverview.sidePanel.rfpCloseDate')} value={closeDate} />
      <ProjectInfoPanel variant="traro" label={t('pages.projectOverview.sidePanel.lastUpdated')} value={lastUpdate} />
    </div>
  );
};

export default ProjectInfo;
