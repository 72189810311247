import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormikTextInput from 'components/forms/formik/FormikTextInput/FormikTextInput';

export interface TextQuestionProps {
  disabled?: boolean;
  name: string;
  questionValues: string;
}

const TextQuestion: FC<TextQuestionProps> = ({ disabled, name, questionValues }) => {
  const { t } = useTranslation();

  return (
    <FormikTextInput
      name={name}
      noLabel
      variant="filled"
      placeholder={t('common.form.textPlaceholder')}
      disabled={disabled}
      softMaxCharacters={Number(questionValues)}
    />
  );
};

export default TextQuestion;
