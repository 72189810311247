import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Note from 'components/Note/Note';
import ArchElementSpecific from 'types/ArchElementSpecific';
import CommonTransComponents from 'providers/i18n/CommonTransComponents';

import BestPracticeBrick from './components/BestPracticeBrick/BestPracticeBrick';

import './BricksElementsDescription.scss';

export interface BricksElementsDescriptionProps {}

const BricksElementsDescription: FC<BricksElementsDescriptionProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="enkrateia-bricks-elements-description">
      <div className="main">
        <h2>{t('pages.projectOverview.archFramework.bricks.title')}</h2>
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_1} />
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_2} />
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_3} />
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_4} />
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_5} />
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_6} />
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_7} />
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_8} />
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_9} />
        <BestPracticeBrick brick={ArchElementSpecific.BRICK_10} />
      </div>
      <Note>
        <span>
          <Trans
            t={t}
            i18nKey="pages.projectOverview.archFramework.elements.bricks"
            components={CommonTransComponents}
          />
        </span>
      </Note>
    </div>
  );
};

export default BricksElementsDescription;
