import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import useUpdatedRef from '@restart/hooks/useUpdatedRef';

import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';

import { useDebouncedAsyncCallback } from 'hooks/useDebouncedAsyncCallback';

import AutoSaveContext from './AutoSaveWatcher.context';
import AutoSaveIndicator from './components/AutoSaveIndicator/AutoSaveIndicator';

export interface AutoSaveWatcherProps<V> {
  delay?: number;
  manualSubmit?: (values: V) => Promise<void>;
  children: ReactNode;
  allowInvalidSubmission?: boolean;
  disabled?: boolean;
  shouldReSaveAfterAutoSave?: false | ((valuesSentToSave: V, currentValues: V) => boolean);
}

const AutoSaveWatcher = <V,>({
  delay = 750,
  manualSubmit,
  children,
  allowInvalidSubmission,
  disabled = false,
  shouldReSaveAfterAutoSave = false,
}: AutoSaveWatcherProps<V>) => {
  const { submitForm, isValid, values, initialValues, isSubmitting } = useFormikContext<V>();
  const currentValuesRef = useUpdatedRef(values);
  const isReadyToAcceptNewUpdatesRef = useRef(true);

  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(
    async (v: V) => {
      isReadyToAcceptNewUpdatesRef.current = false;

      const submit = manualSubmit ?? submitForm;
      let newValues = v;

      try {
        setLoading(true);
        await submit(v);
      } catch {
        return v;
      } finally {
        setLoading(false);
      }

      if (shouldReSaveAfterAutoSave && shouldReSaveAfterAutoSave(v, currentValuesRef.current)) {
        newValues = await handleSubmit(currentValuesRef.current);
      }

      isReadyToAcceptNewUpdatesRef.current = true;

      return newValues;
    },
    [manualSubmit, submitForm, currentValuesRef],
  );
  const debouncedSubmit = useDebouncedAsyncCallback(handleSubmit, delay);

  const previousValues = useRef(initialValues);
  const previousInitialValues = useRef(initialValues);

  const isSubmittingRef = useUpdatedRef(isSubmitting);
  const refIsValid = useUpdatedRef(isValid || allowInvalidSubmission);
  const refDebouncedSubmit = useUpdatedRef(debouncedSubmit);

  useEffect(() => {
    if (isSubmittingRef.current) {
      return;
    }

    if (disabled) {
      return;
    }

    // If initial values do not equal previous initial values, it means form-reinitialize happened
    if (!isEqual(initialValues, previousInitialValues.current)) {
      previousValues.current = initialValues;
      previousInitialValues.current = initialValues;

      return;
    }

    if (refIsValid.current && !isEqual(previousValues.current, values) && isReadyToAcceptNewUpdatesRef.current) {
      refDebouncedSubmit.current(values).then((newValues) => {
        previousValues.current = newValues as V;
      });
    }
  }, [refIsValid, refDebouncedSubmit, values]);

  return <AutoSaveContext.Provider value={{ loading, disabled }}>{children}</AutoSaveContext.Provider>;
};

AutoSaveWatcher.Indicator = AutoSaveIndicator;

export default AutoSaveWatcher;
