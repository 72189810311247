import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { Link, useSearchParams } from 'react-router-dom';

import Header from 'components/Header/Header';
import Tabs, { TabsRef } from 'components/Tabs/Tabs';
import { ArrowLeftIcon } from 'components/SvgIcons';
import { useSelector } from 'store';
import { canAnswerRFPQuestions } from 'services/permissions';

import './KliparoElementPage.scss';

import useUser from 'hooks/useUser';
import { useRfpElementTickets } from 'api';
import CreateTicketButton from 'components/CreateTicketButton/CreateTicketButton';
import AutoSaveWatcher from 'components/AutoSaveWatcher/AutoSaveWatcher';
import useKliparoElementForm, { FormValues } from 'components/QuestionResponse/hooks/useKliparoElementForm';
import QuestionResponse from 'components/QuestionResponse/QuestionResponse';

import useKliparoElement from './hooks/useKliparoElement';
import ElementBackground from './components/ElementBackground/ElementBackground';
import KliparoElementSubmittedModal from './components/KliparoElementSubmittedModal/KliparoElementSubmittedModal';
import KliparoElementSideBar from './components/KliparoElementSideBar/KliparoElementSideBar';
import ElementTickets from 'components/ElementTickets/ElementTickets';

export interface TraroElementPageProps {}

const KliparoElementPage: FC<TraroElementPageProps> = () => {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const user = useUser();
  const { isReviewer } = user;

  const rfpId = useSelector((store) => store.rfp.data.id);
  const rfpResponse = useSelector((store) => store.rfpResponse.data);

  const [searchParams] = useSearchParams();
  const questionWithResponse = useKliparoElement();
  const { traro_element, rfpElementResponse, question_number, section } = questionWithResponse;
  const { schema, initialValues, onSubmit, onAutoSave } = useKliparoElementForm(questionWithResponse, formRef);

  const tabsRef = useRef<TabsRef>({ setActiveKey: () => null });
  const { data: tickets } = useRfpElementTickets(rfpElementResponse.id);

  return (
    <div className="enkrateia-kliparo-element-page">
      <Header />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        validateOnMount
        enableReinitialize
        innerRef={formRef}
      >
        <AutoSaveWatcher
          manualSubmit={onAutoSave}
          allowInvalidSubmission
          disabled={!canAnswerRFPQuestions(user, rfpResponse, rfpElementResponse)}
        >
          <Form className="content">
            <KliparoElementSideBar
              traroElement={traro_element}
              section={section}
              rfpElementResponse={rfpElementResponse}
              questionNumber={question_number}
              ticketCount={tickets?.length}
            />
            <main>
              <Link to={`/rfp/${rfpId}/rfp-response/${rfpResponse.id}`} className="back-to-section-list">
                <ArrowLeftIcon size={14} color="#05445E" />
                {t('pages.traroElement.goBackToOverview')}
              </Link>
              <div className="tabs-container">
                <Tabs
                  initialTab={searchParams.get('initialTab') ?? (isReviewer ? 'question' : 'context')}
                  innerRef={tabsRef}
                  postTabNamesNode={<CreateTicketButton traroElementResponse={rfpElementResponse} rfpRelated />}
                >
                  <Tabs.Tab tabKey="context" label={t('pages.traroElement.tabBackground')} saveScroll>
                    <ElementBackground traroElement={traro_element} />
                  </Tabs.Tab>
                  <Tabs.Tab tabKey="question" label={t('pages.traroElement.tabQuestions')} saveScroll>
                    <QuestionResponse traroElement={traro_element} rfpElementResponse={rfpElementResponse} />
                  </Tabs.Tab>
                  <Tabs.Tab
                    tabKey="tickets"
                    label={t('pages.traroElement.tabTickets', {
                      ticketCount: tickets?.length ?? '...',
                    })}
                    saveScroll
                  >
                    <ElementTickets tickets={tickets} rfpElementResponse={rfpElementResponse} />
                  </Tabs.Tab>
                </Tabs>
              </div>
            </main>
          </Form>
        </AutoSaveWatcher>
      </Formik>
      <KliparoElementSubmittedModal questionStatus={rfpElementResponse.question_status} />
    </div>
  );
};

export default KliparoElementPage;
