import React, { FC } from 'react';
import KliparoQuestionDto from 'types/api/KliparoQuestion';
import ReactMarkdown from 'react-markdown';

import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon } from 'components/SvgIcons';

import getComponent from './components';

import './KliparoQuestion.scss';

export interface KliparoQuestionProps {
  question: KliparoQuestionDto;
  disabled?: boolean;
}

const KliparoQuestion: FC<KliparoQuestionProps> = ({ question, disabled }) => {
  const QuestionComponent = getComponent(question.question_type);

  return (
    <div className="enkrateia-kliparo-question">
      <div className="question-label">
        <div>
          <ReactMarkdown>{question.question_text}</ReactMarkdown>
        </div>
        {question.question_help && (
          <Tooltip tooltip={question.question_help}>
            <HelpIcon color="#05445E" size={25} />
          </Tooltip>
        )}
      </div>
      <QuestionComponent
        questionValues={question.question_values}
        disabled={disabled}
        name={`questions.${question.id}.value`}
      />
    </div>
  );
};

export default KliparoQuestion;
