import React, { FC } from 'react';
import ArchElementSpecific from 'types/ArchElementSpecific';
import { isCompleted, isUpdateRequired } from 'store/rfpResponse/helpers';

import CheckedCircle from '../CheckedCircle/CheckedCircle';
import ExclamationCircle from '../ExclamationCircle/ExclamationCircle';
import { useArchContextForElement } from '../../../../context/ArchContext';
import useElementSelection from '../../../../hooks/useElementSelection';

import './Text.scss';

export interface TextProps {
  x: number | string;
  y: number | string;
  align: 'start' | 'end';
  id: ArchElementSpecific;
  width?: number | string;
}

const Text: FC<TextProps> = ({ align, x, y, id, width = 260 }) => {
  const { element, response } = useArchContextForElement(id);
  const [isSelected, select] = useElementSelection(id);

  return (
    <svg
      x={x}
      y={y}
      width={width}
      height="41"
      style={{ background: 'red' }}
      opacity={isSelected ? 1 : 0.3}
      onClick={select}
      cursor="pointer"
      className="enkrateia-arch-text"
    >
      {isCompleted(response) && <CheckedCircle x={align === 'end' ? Number(width) - 35 : '0'} y="0" />}
      {isUpdateRequired(response) && <ExclamationCircle x={align === 'end' ? Number(width) - 35 : '0'} y="0" />}
      <foreignObject x={align === 'end' ? '0' : '35'} y="5" width={Number(width) - 45} height="36">
        <p className={align}>{element.element_name}</p>
      </foreignObject>
    </svg>
  );
};

export default Text;
