import React, { FC } from 'react';
import ArchElementSpecific from 'types/ArchElementSpecific';
import ArchElementGeneric from 'types/ArchElementGeneric';
import { isCompleted } from 'store/rfpResponse/helpers';

import { useArchContextForElement } from '../../../../context/ArchContext';
import useElementSelection from '../../../../hooks/useElementSelection';

export interface KeystoneProps {
  x: number;
  y: number;
}

const STROKE = 3;

const Background: FC<KeystoneProps> = ({ x, y }) => {
  const { response } = useArchContextForElement(ArchElementSpecific.BACKGROUND);
  const completed = isCompleted(response);
  const [isSelected, select] = useElementSelection(ArchElementSpecific.BACKGROUND);

  return (
    <svg
      x={x - STROKE}
      y={y - STROKE}
      width={150 + 2 * STROKE}
      height={36 + 2 * STROKE}
      className={`enkrateia-arch-brick ${ArchElementGeneric.BRICK}`}
      opacity={isSelected ? 1 : 0.3}
      onClick={select}
      cursor="pointer"
    >
      <rect
        width="150"
        x={STROKE}
        y={STROKE}
        height="34"
        rx="6.5"
        stroke="#DDB483"
        fill={completed ? '#DDB483' : 'transparent'}
        strokeWidth="3"
      />
    </svg>
  );
};

export default Background;
