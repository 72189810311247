import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TraroElementResponse from 'types/api/TraroElementResponse';

import Button from '../Button/Button';
import { FilledPlusIcon } from '../SvgIcons';
import PopperWithFocusTrap from '../PopperWIthFocusTrap/PopperWithFocusTrap';
import CreateTicketModal from './components/CreateTicketModal/CreateTicketModal';

import './CreateTicketButton.scss';
export interface CreateTicketButtonProps {
  traroElementResponse?: TraroElementResponse;
  rfpRelated?: boolean;
  disabled?: boolean;
}

const CreateTicketButton: FC<CreateTicketButtonProps> = ({ traroElementResponse, disabled, rfpRelated }) => {
  const { t } = useTranslation();
  const popperRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        ref={popperRef}
        className="enkrateia-create-ticket-button"
        variant="link"
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        <FilledPlusIcon size={20} color="#05445E" />
        {t('pages.projectOverview.mainPanel.createTicket')}
      </Button>
      <PopperWithFocusTrap
        open={open}
        referenceElement={popperRef.current}
        popperOptions={{
          placement: 'bottom-start',
          strategy: 'fixed',
          modifiers: [{ name: 'offset', options: { offset: [0, -45] } }],
        }}
      >
        <CreateTicketModal
          onClose={() => setOpen(false)}
          traroElementResponse={traroElementResponse}
          rfpRelated={rfpRelated}
        />
      </PopperWithFocusTrap>
      {open && <div className="enkrateia-create-ticket-overlay"></div>}
    </>
  );
};

export default CreateTicketButton;
