enum ArchElementSpecific {
  SPRINGER_LEFT = 'Springer-left',
  SPRINGER_RIGHT = 'Springer-right',
  KEYSTONE = 'Keystone',
  VOUSSOIR_RIGHT_1 = 'Voussoir-right1',
  VOUSSOIR_RIGHT_2 = 'Voussoir-right2',
  VOUSSOIR_LEFT_1 = 'Voussoir-left1',
  VOUSSOIR_LEFT_2 = 'Voussoir-left2',
  BRICK_1 = 'Brick1',
  BRICK_2 = 'Brick2',
  BRICK_3 = 'Brick3',
  BRICK_4 = 'Brick4',
  BRICK_5 = 'Brick5',
  BRICK_6 = 'Brick6',
  BRICK_7 = 'Brick7',
  BRICK_8 = 'Brick8',
  BRICK_9 = 'Brick9',
  BRICK_10 = 'Brick10',
  BACKGROUND = 'Background',
}

export default ArchElementSpecific;
