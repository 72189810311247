import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormikRichTextAreaInput from 'components/forms/formik/FormikRichTextAreaInput/FormikRichTextAreaInput';

export interface TextAreaQuestionProps {
  disabled?: boolean;
  name: string;
  questionValues: string;
}

const TextAreaQuestion: FC<TextAreaQuestionProps> = ({ disabled, name, questionValues }) => {
  const { t } = useTranslation();

  return (
    <FormikRichTextAreaInput
      name={name}
      variant="filled"
      rows={6}
      placeholder={t('common.form.textAreaPlaceholder')}
      disabled={disabled}
      autoHeight
      softMaxCharacters={Number(questionValues)}
    />
  );
};

export default TextAreaQuestion;
