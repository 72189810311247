import { createContext, useContext } from 'react';

interface AutoSaveContextState {
  loading: boolean;
  disabled: boolean;
}

const AutoSaveContext = createContext<AutoSaveContextState>({ loading: false, disabled: false });

export const useAutoSaveContext = () => useContext(AutoSaveContext);

export default AutoSaveContext;
