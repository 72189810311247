import React, { forwardRef } from 'react';

import useFormikConnection, { TouchTrigger, WithoutInjectedProps } from '../hooks/useFormikConnection';
import SliderInput, { SliderInputProps } from '../../raw/SliderInput';
import { SliderRef } from 'rc-slider';

export interface FormikTextInputProps extends WithoutInjectedProps<SliderInputProps, 'onChange'> {
  name: string;
  touchedTrigger?: TouchTrigger;
}

const FormikTextInput = forwardRef<SliderRef, FormikTextInputProps>(({ name, ...props }, ref) => {
  const { setValue, ...other } = useFormikConnection<number, HTMLDivElement>(name, props);

  return <SliderInput {...props} {...other} ref={ref} label={props.label} onChange={setValue} />;
});

export default FormikTextInput;
