import React, { cloneElement, forwardRef, ReactNode, useCallback } from 'react';
import Slider, { SliderProps, SliderRef } from 'rc-slider';
import 'rc-slider/assets/index.css';

import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon } from 'components/SvgIcons';

import useSliderMarkConfig, { SliderMarkConfig } from './hooks/useSliderMarkConfig';
import Handle from './components/Handle/Handle';

import './SliderInput.scss';

export interface SliderInputProps extends Omit<SliderProps<number>, 'marks'> {
  marks?: Record<string | number, SliderMarkConfig>;
  label?: ReactNode;
  noLabel?: boolean;
  id?: string;
  required?: boolean;
  hint?: ReactNode;
  variant?: 'standard' | 'filled';
}

const SingleSlider = Slider as React.ForwardRefExoticComponent<SliderProps<number> & React.RefAttributes<SliderRef>>;

const SliderInput = forwardRef<SliderRef, SliderInputProps>(
  ({ variant = 'standard', label, noLabel, id, className = '', required, hint, ...props }, ref) => {
    const [marks, tooltips] = useSliderMarkConfig(props.marks, props.min, props.max);

    const handleRender = useCallback<NonNullable<SliderProps['handleRender']>>(
      (node, { value }) => {
        const newProps = {
          ...node.props,
          children: (
            <div className="static-tooltip">
              <div className="static-tooltip-content">{tooltips?.[value] ?? value}</div>
            </div>
          ),
          // // @ts-ignore
          // ['data-tooltip']: tooltips?.[value] ?? value,
        };

        return (
          <Handle value={value} visible={false} tooltips={tooltips}>
            {cloneElement(node, newProps)}
          </Handle>
        );
      },
      [tooltips],
    );

    return (
      <div className={`enkrateia-slider ${className} variant-${variant}`}>
        <label className={noLabel ? 'sr-only' : ''}>
          {label}
          {required && '*'}
          {hint && (
            <Tooltip tooltip={hint}>
              <HelpIcon color="#05445E" size={25} />
            </Tooltip>
          )}
        </label>
        <SingleSlider {...props} ref={ref} handleRender={handleRender} marks={marks} />
      </div>
    );
  },
);

export default SliderInput;
