import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { FilledPlusIcon } from 'components/SvgIcons';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import { useCreateReviewTicketsMutation } from 'api';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'store';

import './StartReviewProcess.scss';

const StartReviewProcess = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const navigate = useNavigate();

  const rfpId = useSelector((store) => store.rfp.data.id);
  const responseId = useSelector((store) => store.rfpResponse.data.id);

  const { mutateAsync: createReviewTickets, isLoading } = useCreateReviewTicketsMutation({
    onSuccess: () => {
      setIsOpen(false);
      setSuccessOpen(true);
    },
    onError: () => {
      setErrorOpen(true);
      setIsOpen(false);
    },
  });

  return (
    <>
      <Button className="enkrateia-start-review-process" onClick={() => setIsOpen(true)}>
        <FilledPlusIcon className="plus-icon" size={20} color="#000" />
        {t('pages.projectOverview.mainPanel.startReviewProcess.button')}
      </Button>
      <Modal
        variant="kliparo"
        title={t('pages.projectOverview.mainPanel.startReviewProcess.modalTitle')}
        className="enkrateia-start-review-modal"
        isOpen={isOpen}
      >
        <div className="enkrateia-start-review-modal-content">
          <Button variant="primary" onClick={() => createReviewTickets({ responseId })}>
            {t('pages.projectOverview.mainPanel.startReviewProcess.confirm')}
          </Button>
          <Button variant="light-grey-filled" onClick={() => setIsOpen(false)}>
            {t('pages.projectOverview.mainPanel.startReviewProcess.cancel')}
          </Button>
        </div>
        <LoaderOverlay open={isLoading} />
      </Modal>
      <Modal
        variant="kliparo"
        title={t('pages.projectOverview.mainPanel.startReviewProcess.success.title')}
        isOpen={isSuccessOpen}
        className="enkrateia-start-review-modal"
      >
        <div className="enkrateia-start-review-modal-content success">
          <p>{t('pages.projectOverview.mainPanel.startReviewProcess.success.content')}</p>
          <Button
            variant="light-grey-filled"
            onClick={() => {
              setSuccessOpen(false);
              navigate(`/rfp/${rfpId}/rfp-response/${responseId}/rfp-element-response/Background`);
            }}
          >
            {t('common.ok')}
          </Button>
        </div>
      </Modal>
      <Modal
        variant="kliparo"
        title={t('pages.projectOverview.mainPanel.startReviewProcess.error.title')}
        isOpen={isErrorOpen}
        className="enkrateia-start-review-modal"
      >
        <div className="enkrateia-start-review-modal-content error">
          <p>{t('pages.projectOverview.mainPanel.startReviewProcess.error.content')}</p>
          <Button variant="light-grey-filled" onClick={() => setErrorOpen(false)}>
            {t('common.ok')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default StartReviewProcess;
