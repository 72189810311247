import { uniqBy } from 'lodash';
import { useMemo } from 'react';

import { useCurrentOrganizationUsers, useOrganizationUsers } from 'api';
import { InputOption } from 'components/forms/raw/SelectInput';
import RFP from 'types/api/RFP';
import RFPResponse from 'types/api/RFPResponse';
import TicketConnectionDetailsDto from 'types/api/TicketConnectionDetailsDto';
import TicketDto from 'types/api/TicketDto';
import UserDto from 'types/api/UserDto';
import useUser from '../../../../../hooks/useUser';

export const userToInput = (user: UserDto): InputOption<number, UserDto> => ({
  value: user.id,
  label: `${user.first_name} ${user.last_name}`,
  meta: user,
});

const useTicketAssignedUserOptions = (
  ticket: TicketDto,
  availableRfps: InputOption<number, RFP>[] | undefined,
  rfpResponse: RFPResponse | undefined | null,
  ticketConnectedElement: Pick<TicketConnectionDetailsDto, 'traro_element_response_id'> | undefined | null,
): InputOption<number, UserDto>[] => {
  const { isReviewer } = useUser();
  const rfpResponsePointOfContact = rfpResponse?.point_of_contact;
  const responsesList = rfpResponse?.traro_element_response_list ?? [];

  const elementPointOfContact = responsesList.find(
    (response) => response.id === ticketConnectedElement?.traro_element_response_id,
  )?.point_of_contact;

  const { data: userOrganizationUsers = [] } = useCurrentOrganizationUsers();
  const { data: ticketOrganizationUsers = [] } = useOrganizationUsers(ticket.organization.id, !isReviewer);

  return useMemo(() => {
    const users: InputOption<number, UserDto>[] = [];

    // User from RFP
    if (availableRfps && availableRfps.length > 0) {
      const RFPPointOfContact = availableRfps[0].meta.point_of_contact;

      users.push(userToInput(RFPPointOfContact));
    }

    // User from RFP Response
    if (rfpResponsePointOfContact) {
      users.push(userToInput(rfpResponsePointOfContact));
    }

    // User from Element Response
    if (elementPointOfContact) {
      users.push(userToInput(elementPointOfContact));
    }

    // Users from comments
    users.push(...ticket.ticket_comment_list.map((comment) => userToInput(comment.commenter)));

    // Users from user organization
    users.push(...userOrganizationUsers.map(userToInput));

    // Users from ticket organization
    users.push(...ticketOrganizationUsers.map(userToInput));

    return uniqBy(users, 'value').filter((user) => user.value !== undefined);
  }, [
    ticket,
    userOrganizationUsers,
    rfpResponsePointOfContact,
    elementPointOfContact,
    availableRfps,
    ticketOrganizationUsers,
  ]);
};

export default useTicketAssignedUserOptions;
