import React, { FC } from 'react';
import { useSelector } from 'store';
import ReactMarkdown from 'react-markdown';

import RfpMode from 'types/RfpMode';

import ArchListSwitch from './components/ArchListSwitch/ArchListSwitch';
import ListOverview from './components/ListOverview/ListOverview';
import ArchOverview from './components/ArchOverview/ArchOverview';
import CreateTicketButton from 'components/CreateTicketButton/CreateTicketButton';
import Actions from './components/Actions/Actions';

import './TraroProjectOverview.scss';
import useUser from '../../../../hooks/useUser';

export interface ProjectOverviewProps {}

const TraroProjectOverview: FC<ProjectOverviewProps> = () => {
  const name = useSelector((store) => store.rfp.data.name);
  const mode = useSelector((store) => store.rfp.mode);
  const description = useSelector((store) => store.rfp.data.rfp_description);
  const user = useUser();

  return (
    <div className="enkrateia-project-overview">
      <div className="head">
        <div className="title">
          <div className="head-content">
            <div className="description">
              <h1>{name}</h1>
              <ReactMarkdown linkTarget="_blank" className="context-0">
                {description}
              </ReactMarkdown>
            </div>
            <div className="actions">
              <CreateTicketButton rfpRelated />
            </div>
          </div>
          {user.isReviewer && <Actions />}
        </div>
        <ArchListSwitch />
      </div>
      <div className="body">{mode === RfpMode.ARCH ? <ArchOverview /> : <ListOverview />}</div>
    </div>
  );
};
export default TraroProjectOverview;
