import TicketDto from 'types/api/TicketDto';
import { Cell, Row } from '@tanstack/react-table';
import useTicketAssignedUserOptions from 'components/TicketDetails/components/TicketForm/hooks/useTicketAssignedUserOptions';
import { useAvailableRFPs, useSaveTicketMutation } from 'api';
import { useSelector } from 'store';
import TraroElementResponse from 'types/api/TraroElementResponse';
import SelectInput, { InputOption } from 'components/forms/raw/SelectInput';
import { useState } from 'react';
import UserDto from 'types/api/UserDto';
import TicketStatus from '../../../../../../../../types/TicketStatus';
import TicketSeverityType from '../../../../../../../../types/TicketSeverityType';

export interface AssignedToCellProps {
  cell: Cell<TicketDto, TraroElementResponse | null>;
  row: Row<TicketDto>;
}

const EditableAssignedTo = ({
  ticket,
  elementResponse,
}: {
  ticket: TicketDto;
  elementResponse: TraroElementResponse | null;
}) => {
  const rfpResponse = useSelector((state) => state.rfpResponse.data);

  const { data: availableRFPs, isLoading: isAvailableRFPsLoading } = useAvailableRFPs(undefined);
  const options = useTicketAssignedUserOptions(
    ticket,
    availableRFPs,
    rfpResponse,
    elementResponse === null || !elementResponse.id
      ? null
      : {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          traro_element_response_id: elementResponse.id,
        },
  );

  const [value, setValue] = useState<InputOption<number, UserDto | null> | null>(
    ticket.assigned_user
      ? {
          label: `${ticket.assigned_first_name} ${ticket.assigned_last_name}`,
          value: ticket.assigned_user as unknown as number,
          meta: null,
        }
      : null,
  );

  const { mutateAsync: saveTicket, isLoading } = useSaveTicketMutation();

  return (
    <SelectInput
      variant="filled"
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
        if (newValue) {
          saveTicket({
            ticket: {
              ...ticket,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              severity_rating: ticket.severity_rating ?? TicketSeverityType.NOT_ASSIGNED,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              assigned_user: newValue.value,
            },
          });
        }
      }}
      options={options}
      disabled={isAvailableRFPsLoading || isLoading}
    />
  );
};

const AssignedToCell = ({ row, cell }: AssignedToCellProps) => {
  if (!row.getIsExpanded() || row.original.ticket_status === TicketStatus.CLOSED) {
    return `${row.original.assigned_first_name} ${row.original.assigned_last_name}`;
  }

  return <EditableAssignedTo ticket={row.original} elementResponse={cell.getValue()} />;
};

export default AssignedToCell;
