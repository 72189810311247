import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Row } from '@tanstack/react-table';
import { isArray } from 'lodash';

import { useRfpResponsesWithStats } from 'api';
import CommonTransComponents from 'providers/i18n/CommonTransComponents';
import Table from 'components/Table/Table';
import Announcement from 'components/Announcement/Announcement';
import RFPResponseWithRFPAndStats from 'types/api/RFPResponseWithRFPAndStats';
import useUser from 'hooks/useUser';

import useColumns from './hooks/useColumns';

import './Assessments.scss';

export interface AssessmentsProps {}

const Assessments: FC<AssessmentsProps> = () => {
  const { t } = useTranslation();
  const { isReviewer } = useUser();
  const columns = useColumns(isReviewer);
  const { data, isFetching } = useRfpResponsesWithStats();
  const [announcementOpen, setAnnouncementOpen] = useState(false);

  useEffect(() => {
    if (!isFetching && isArray(data) && data.length === 0) {
      setAnnouncementOpen(true);
    }
  }, [data, isFetching]);

  return (
    <div className="enkrateia-dashboard-assessments">
      <Announcement open={announcementOpen} onRequestClose={() => setAnnouncementOpen(false)}>
        <Trans t={t} i18nKey="pages.dashboard.head.announcement" components={CommonTransComponents}></Trans>
      </Announcement>
      <div className="title">
        <h2>
          {isReviewer
            ? t('pages.dashboard.assessments.reviewerTitle')
            : t('pages.dashboard.assessments.proponentTitle')}
        </h2>
      </div>
      <div className="content">
        <Table
          data={data}
          columns={columns}
          getRowLink={(row: Row<RFPResponseWithRFPAndStats>) =>
            `/rfp/${row.original.traro_rfp.id}/rfp-response/${row.original.id}`
          }
        />
      </div>
    </div>
  );
};

export default Assessments;
