import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import PreviousNextNavigation from 'components/PreviousNextNavigation/PreviousNextNavigation';
import TraroElement from 'types/api/TraroElement';
import ArchElementGeneric from 'types/ArchElementGeneric';
import { useSelector } from 'store';

import './Naviagtion.scss';

export interface NavigationProps {
  traroElement: TraroElement;
}

const Navigation = ({ traroElement }: NavigationProps) => {
  const rfpId = useSelector((store) => store.rfp.data.id);
  const rfpResponseId = useSelector((store) => store.rfpResponse.data.id);
  const traroElementList = useSelector((store) => store.rfp.data.traro_element_list);
  const [searchParams] = useSearchParams();

  const [prev, next] = useMemo(() => {
    const baseUrl = `/rfp/${rfpId}/rfp-response/${rfpResponseId}/rfp-element-response/`;

    const orderedElements = traroElementList
      .filter((element) => element.arch_element_generic !== ArchElementGeneric.BRICK)
      .sort((a, b) => a.element_number - b.element_number);

    const currentIndex = orderedElements.findIndex(
      (element) => element.arch_element_specific === traroElement.arch_element_specific,
    );

    const prevElement = currentIndex > 0 ? orderedElements[currentIndex - 1] : null;
    const nextElement = currentIndex < orderedElements.length - 1 ? orderedElements[currentIndex + 1] : null;

    return [
      prevElement ? `${baseUrl}${prevElement.arch_element_specific}?${searchParams}` : undefined,
      nextElement ? `${baseUrl}${nextElement.arch_element_specific}?${searchParams}` : undefined,
    ];
  }, [rfpId, rfpResponseId, traroElement]);

  return (
    <div className="traro-element-navigation">
      <PreviousNextNavigation prevUrl={prev} nextUrl={next} circleColor="black">
        {traroElement.element_number}. {traroElement.element_name}
      </PreviousNextNavigation>
    </div>
  );
};

export default Navigation;
