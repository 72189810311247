import React from 'react';
import { useCollapse } from 'react-collapsed';

import { ChevronRight } from 'components/SvgIcons';

import './CollapsibleSection.scss';

export interface CollapsibleSectionProps {
  children: React.ReactNode | React.ReactNode[];
  title: React.ReactNode | React.ReactNode[];
  defaultExpanded?: boolean;
  className?: string;
}

const CollapsibleSection = ({ title, children, defaultExpanded, className = '' }: CollapsibleSectionProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded,
  });

  return (
    <div className={`enkrateia-collapsible-section ${className}`}>
      <button className={`header ${isExpanded ? 'open' : 'closed'}`} {...getToggleProps()}>
        <ChevronRight />
        <div>{title}</div>
      </button>
      <section {...getCollapseProps()}>{children}</section>
    </div>
  );
};

export default CollapsibleSection;
