import React, { ReactNode } from 'react';
import Select, { InputProps, OnChangeValue, SetValueAction } from 'react-select';

import useRequiredId from 'hooks/useRequiredId';

import './SelectInput.scss';
import Tooltip from '../../../Tooltip/Tooltip';
import { HelpIcon } from '../../../SvgIcons';

type BaseInputOption<T = any> = {
  value: T;
  label: string | ReactNode;
};

export type InputOption<T = any, M = void> = M extends void
  ? BaseInputOption<T>
  : BaseInputOption<T> & {
      meta: M;
    };

type Variant = 'standard' | 'filled' | 'outlined' | 'raw';

export interface SelectInputProps<Option extends InputOption, IsMulti extends boolean = false>
  extends Partial<Omit<InputProps<Option, IsMulti>, 'onChange' | 'setValue' | 'value'>> {
  variant?: Variant;
  inline?: boolean;
  label?: string;
  error?: string | ReactNode;
  hint?: string | ReactNode;
  touched?: boolean;
  fullWidth?: boolean;
  isClearable?: boolean;
  value: OnChangeValue<Option, IsMulti>;
  onChange: (newValue: OnChangeValue<Option, IsMulti>, action: SetValueAction, option?: Option | undefined) => void;
}

/**
 * Select element built on top of battle proven react-select component.
 */
const SelectInput = <Option extends InputOption, IsMulti extends boolean = false>(
  props: SelectInputProps<Option, IsMulti>,
) => {
  const {
    variant = 'standard',
    id,
    label,
    error,
    touched = false,
    onChange,
    fullWidth = false,
    inline = false,
    required = false,
    hint,
    className = '',
    disabled,
    ...rest
  } = props;

  const ensuredId = useRequiredId(id);
  const errorId = `${ensuredId}-error`;

  const hasError = Boolean(error && error !== '' && touched);

  return (
    <div
      className={`enkrateia-select-input ${inline ? 'inline' : ''} variant-${variant} ${className} ${
        fullWidth ? 'full-width' : ''
      }`}
    >
      {label && (
        <label htmlFor={ensuredId}>
          {label}
          {required && '*'}
          {hint && (
            <Tooltip tooltip={hint}>
              <HelpIcon color="#05445E" size={25} />
            </Tooltip>
          )}
        </label>
      )}
      <Select<Option, IsMulti>
        {...rest}
        isDisabled={disabled}
        className="enkrateia-react-select-container"
        classNamePrefix="react-select"
        // @ts-ignore
        onChange={onChange}
        aria-invalid={hasError}
        aria-errormessage={errorId}
        id={ensuredId}
      />
      {hasError && (
        <span id={errorId} className="error-label">
          {error}
        </span>
      )}
    </div>
  );
};

export default SelectInput;
