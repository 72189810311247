/* eslint-disable @typescript-eslint/naming-convention */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as rfp from 'services/rfp';
import RFPResponse from 'types/api/RFPResponse';
import ArchElementSpecific from 'types/ArchElementSpecific';
import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionStatus from 'types/QuestionStatus';
import ArchElementsMap from 'types/ArchElementsMap';
import TraroElement from 'types/api/TraroElement';
import KliparoQuestion from 'types/api/KliparoQuestion';
import KliparoResponse from 'types/api/KliparoResponse';

import { getDefaultResponseElement } from './helpers';

import { RootState } from '../store';
import UserDto from '../../types/api/UserDto';
import RFPResponseStatus from '../../types/RFPResponseStatus';
import RFPSubmitResponseStatus from '../../types/RFPSubmitResponseStatus';

export const saveRfpResponse = createAsyncThunk<RFPResponse, { rfpId: number; overrides?: Partial<RFPResponse> }>(
  'rfpResponse/saveRfpResponse',
  async ({ rfpId, overrides = {} }, thunkApi) => {
    const currentResponse = (thunkApi.getState() as RootState).rfpResponse.data;
    const { isReviewer, isProponent } = (thunkApi.getState() as RootState).auth.user;

    const newData = { ...currentResponse, ...overrides };

    newData.traro_element_response_list = newData.traro_element_response_list.filter(
      (element) => element.id !== undefined,
    );

    if (isProponent && currentResponse.rfp_response_status === RFPResponseStatus.OPEN) {
      newData.rfp_response_status = RFPResponseStatus.IN_REVIEW;
      newData.submissions_remaining -= 1;
    } else if (isReviewer && currentResponse.rfp_response_status === RFPResponseStatus.IN_REVIEW) {
      newData.rfp_response_status = RFPResponseStatus.FINAL;
    }

    const { data } = await rfp.saveRfpResponse(rfpId, newData);

    return data;
  },
);

export const submitRfpResponse = createAsyncThunk<RFPResponse>('rfpResponse/submitRfpResponse', async (_, thunkApi) => {
  const rfpId = (thunkApi.getState() as RootState).rfp.data.id;
  const currentResponse = (thunkApi.getState() as RootState).rfpResponse.data;

  const { data } = await rfp.saveRfpResponse(rfpId, {
    ...currentResponse,
    rfp_response_status: RFPResponseStatus.IN_REVIEW,
    rfp_submit_response_status: RFPSubmitResponseStatus.ALLOWED,
  });

  return data;
});

export const updateRfpResponseName = createAsyncThunk<string, { rfpId: number; newProjectName: string }>(
  'rfpResponse/updateRfpResponseName',
  async ({ rfpId, newProjectName }, thunkApi) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    await thunkApi.dispatch(saveRfpResponse({ rfpId, overrides: { project_name: newProjectName } }));

    return newProjectName;
  },
);

export const updateRfpResponseDueDate = createAsyncThunk<string | null, { rfpId: number; newDueDate: string | null }>(
  'rfpResponse/updateRfpResponseDueDate',
  async ({ rfpId, newDueDate }, thunkApi) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    await thunkApi.dispatch(saveRfpResponse({ rfpId, overrides: { review_due_date: newDueDate } }));

    return newDueDate;
  },
);

export const updateRfpResponseScoreAndStatus = createAsyncThunk<
  { score: number | null; status: RFPResponseStatus },
  { rfpId: number; score: number | null; status: RFPResponseStatus }
>('rfpResponse/updateRfpResponseScoreAndStatus', async ({ rfpId, score, status }, thunkApi) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  await thunkApi.dispatch(
    saveRfpResponse({ rfpId, overrides: { rfp_response_score: score, rfp_response_status: status } }),
  );

  return { score, status };
});

export const updateRfpResponsePointOfContact = createAsyncThunk<
  UserDto | null,
  { rfpId: number; newPointOfContact: UserDto | null }
>('rfpResponse/updateRfpResponsePointOfContact', async ({ rfpId, newPointOfContact }, thunkApi) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  await thunkApi.dispatch(saveRfpResponse({ rfpId, overrides: { point_of_contact: newPointOfContact as UserDto } }));

  return newPointOfContact;
});

const getQuestionWithinTraroElementList = (
  traroElementList: TraroElement[],
  questionId: number,
): KliparoQuestion | null => {
  for (const traroElement of traroElementList) {
    for (const question of traroElement.kliparo_question_list) {
      if (question.id === questionId) {
        return question;
      }
    }
  }

  return null;
};

interface KliparoResponseData {
  id?: number | null;
  questionId: number;
  responseText: string;
}

export const updateRfpElementResponse = createAsyncThunk<
  { data: TraroElementResponse; noStoreSync?: boolean },
  {
    elementResponseId?: number | undefined;
    rfpId: number;
    rfpResponseId: number;
    archElementSpecific?: ArchElementSpecific;
    responseText: string;
    elementId: number;
    kliparoResponses?: KliparoResponseData[];
    questionStatus?: QuestionStatus;
    noStoreSync?: boolean;
  }
>(
  'rfpResponse/updateRfpElementResponse',
  async (
    {
      elementResponseId,
      rfpId,
      rfpResponseId,
      archElementSpecific,
      elementId,
      responseText,
      kliparoResponses = [],
      questionStatus: requestedQuestionStatus,
      noStoreSync,
    },
    thunkApi,
  ) => {
    if (archElementSpecific && ArchElementsMap[archElementSpecific] === undefined) {
      throw new Error('Invalid arch element: ' + archElementSpecific);
    }

    let traroResponseElement = (thunkApi.getState() as RootState).rfpResponse.data.traro_element_response_list.find(
      (response: TraroElementResponse) => response.traro_element === elementId || response.id === elementResponseId,
    );

    if (traroResponseElement === undefined) {
      traroResponseElement = getDefaultResponseElement(elementId, archElementSpecific);
    }

    const traroElements = (thunkApi.getState() as RootState).rfp.data.traro_element_list;
    const questionStatus = requestedQuestionStatus ?? traroResponseElement.question_status;

    const updatedTraroResponseElement: TraroElementResponse = {
      ...traroResponseElement,
      traro_response: rfpResponseId,
      traro_element: elementId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      question_status: questionStatus === QuestionStatus.BEGIN ? QuestionStatus.IN_PROGRESS : questionStatus,
      response_text: responseText,
      ticket_list: traroResponseElement.ticket_list ?? [],
      kliparo_response_list: kliparoResponses
        .filter((r) => r.responseText !== '')
        .map((kliparoResponse) => {
          const question = getQuestionWithinTraroElementList(traroElements, Number(kliparoResponse.questionId));

          const response: KliparoResponse = {
            kliparo_question_id: question?.id,
            kliparo_question: question,
            kliparo_response: kliparoResponse.responseText,
            traro_element_response:
              traroResponseElement?.kliparo_response_list.find(
                (e) => e.kliparo_question?.id === kliparoResponse.questionId,
              )?.traro_element_response ?? null,
          };

          if (kliparoResponse.id) {
            response.id = kliparoResponse.id;
          }

          return response;
        }),
    };

    const { data } = await rfp.saveRfpTraroElement(rfpId, updatedTraroResponseElement);

    return { data, noStoreSync };
  },
);

export const addElementComment = createAsyncThunk<
  TraroElementResponse,
  { rfpId: number; archElementSpecific: ArchElementSpecific; comment: string }
>('rfpResponse/addElementComment', async ({ archElementSpecific, comment }, thunkApi) => {
  if (ArchElementsMap[archElementSpecific] === undefined) {
    throw new Error('Invalid arch element: ' + archElementSpecific);
  }

  const traroResponseElement = (thunkApi.getState() as RootState).rfpResponse.data.traro_element_response_list.find(
    (element) => element.arch_element_specific === archElementSpecific,
  );

  if (traroResponseElement === undefined || traroResponseElement.id === undefined) {
    throw new Error('Element not saved yet!');
  }

  const { data } = await rfp.addRfpTraroElementComment(traroResponseElement.id, { response_comment: comment });

  return data;
});

export const updateRfpElementScore = createAsyncThunk<
  TraroElementResponse,
  { rfpId: number; rfpResponseId: number; archElementSpecific?: ArchElementSpecific; score: number; elementId: number }
>(
  'rfpResponse/updateRfpElementScore',
  async ({ rfpId, rfpResponseId, archElementSpecific, score, elementId }, thunkApi) => {
    if (archElementSpecific && ArchElementsMap[archElementSpecific] === undefined) {
      throw new Error('Invalid arch element: ' + archElementSpecific);
    }

    let traroResponseElement = (thunkApi.getState() as RootState).rfpResponse.data.traro_element_response_list.find(
      (response: TraroElementResponse) => response.traro_element === elementId,
    );

    if (traroResponseElement === undefined) {
      traroResponseElement = getDefaultResponseElement(elementId, archElementSpecific);
    }

    const updatedTraroResponseElement: TraroElementResponse = {
      ...traroResponseElement,
      traro_response: rfpResponseId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      question_score: score,
    };

    const { data } = await rfp.saveRfpTraroElement(rfpId, updatedTraroResponseElement);

    // @ts-ignore
    // await thunkApi.dispatch(loadRfpResponse(rfpId));

    return data;
  },
);

export const updateKliparoRfpElementScore = createAsyncThunk<
  TraroElementResponse,
  { rfpId: number; rfpResponseId: number; score: number | null; elementId: number; status: QuestionStatus }
>('rfpResponse/updateKliparoRfpElementScore', async ({ rfpId, rfpResponseId, score, elementId, status }, thunkApi) => {
  let traroResponseElement = (thunkApi.getState() as RootState).rfpResponse.data.traro_element_response_list.find(
    (response: TraroElementResponse) => response.id === elementId,
  );

  if (traroResponseElement === undefined) {
    throw new Error('Invalid element!');
  }

  const updatedTraroResponseElement: TraroElementResponse = {
    ...traroResponseElement,
    traro_response: rfpResponseId,
    question_score: score === null ? traroResponseElement.question_score : score,
    question_status: status,
  };

  const { data } = await rfp.saveRfpTraroElement(rfpId, updatedTraroResponseElement);

  return data;
});

export const updateKliparoRfpElementStatus = createAsyncThunk<
  TraroElementResponse,
  { rfpId: number; rfpResponseId: number; traroElementResponseId: number; status: QuestionStatus }
>('rfpResponse/markQuestionAs', async ({ rfpId, rfpResponseId, traroElementResponseId, status }, thunkAPI) => {
  const rfpElementResponse = (thunkAPI.getState() as RootState).rfpResponse.data.traro_element_response_list.find(
    (element) => element.id === traroElementResponseId,
  );

  if (!rfpElementResponse) {
    throw new Error('Invalid element id: ' + traroElementResponseId);
  }

  const { data } = await rfp.saveRfpTraroElement(rfpId, {
    ...rfpElementResponse,
    traro_response: rfpResponseId,
    question_status: status,
  });

  return data;
});

export const updateRfpTraroBrick = createAsyncThunk<
  TraroElementResponse,
  { rfpId: number; rfpResponseId: number; archElementSpecific: ArchElementSpecific; questionStatus: QuestionStatus }
>(
  'rfpResponse/updateRfpTraroBrick',
  async ({ rfpId, rfpResponseId, archElementSpecific, questionStatus }, thunkAPI) => {
    let brick = (thunkAPI.getState() as RootState).rfpResponse.data.traro_element_response_list.find(
      (element) => element.arch_element_specific === archElementSpecific,
    );

    if (brick === undefined) {
      brick = getDefaultResponseElement(null, archElementSpecific);
    }

    const responseText = questionStatus === QuestionStatus.BEGIN ? null : 'yes';

    const { data: updatedBrick } = await rfp.saveRfpTraroElement(rfpId, {
      ...brick,
      traro_response: rfpResponseId,
      response_text: responseText,
      question_status: questionStatus,
    });

    return updatedBrick;
  },
);
