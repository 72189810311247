import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import FormikRichTextAreaInput from 'components/forms/formik/FormikRichTextAreaInput/FormikRichTextAreaInput';
import FormikSliderInput, { FormikTextInputProps } from 'components/forms/formik/FormikSliderInput/FormikSliderInput';
import { ElementScoreComponentWithResponse } from 'hooks/useElementScoresWithResponses/useElementScoresWithResponses';
import Markdown from 'components/Markdown/Markdown';

import useIsFeedbackDisabled from '../../hooks/useIsFeedbackDisabled';

import './ResponseCell.scss';
import { useTranslation } from 'react-i18next';

const ResponseCell: ColumnDef<ElementScoreComponentWithResponse, string>['cell'] = ({ getValue, row }) => {
  const value = getValue();
  const disabled = useIsFeedbackDisabled(row.original);
  const { t } = useTranslation();

  const scoringLegends = row.original.elementScoreComponent.scoring_legends;
  const max_points = row.original.elementScoreComponent.max_points;

  const { min, max, step, marks } = useMemo(() => {
    if (!scoringLegends || scoringLegends.length === 0) {
      return { min: 0, max: max_points, step: 0.01, marks: undefined } as const;
    }

    if (scoringLegends.length === 1) {
      return { min: 0, max: scoringLegends[0].point_value, step: 0.01, marks: undefined } as const;
    }

    return {
      min: scoringLegends[0].point_value,
      max: scoringLegends[scoringLegends.length - 1].point_value,
      step: null,
      marks: scoringLegends.reduce((acc, entry) => {
        acc[Number(entry.point_value)] = {
          mark: entry.point_value,
          tooltip: entry.legend_text,
        };

        return acc;
      }, {} as NonNullable<FormikTextInputProps['marks']>),
    };
  }, [max_points, scoringLegends]);

  return (
    <div className="enkrateia-reviewer-scoring-and-feedback-response-cell">
      <Markdown className="investigative-question">{value}</Markdown>
      <FormikRichTextAreaInput
        variant="filled"
        name={`${row.original.elementScoreComponent.id}.responseFeedback`}
        rows={5}
        disabled={disabled}
      />
      <FormikSliderInput
        variant="filled"
        label={t('pages.traroElement.score')}
        name={`${row.original.elementScoreComponent.id}.responseScore`}
        min={min}
        max={max}
        marks={marks}
        step={step}
        disabled={disabled}
      />
    </div>
  );
};

export default ResponseCell;
