import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CircleArrowLeft, CircleArrowRight } from 'components/SvgIcons';

import './PreviousNextNavigation.scss';
import Tooltip from '../Tooltip/Tooltip';

export interface PreviousNextNavigationProps {
  prevUrl: string | undefined;
  nextUrl: string | undefined;
  children: ReactNode;
  circleColor?: string;
}

const PreviousNextNavigation = ({ prevUrl, nextUrl, children, circleColor = 'white' }: PreviousNextNavigationProps) => {
  const { t } = useTranslation();

  return (
    <div className="enkrateia-previous-next-navigation">
      {prevUrl !== undefined ? (
        <Link to={prevUrl}>
          <Tooltip tooltip={t('common.previous')}>
            <CircleArrowLeft size={23} color={circleColor} />
          </Tooltip>
        </Link>
      ) : (
        <span>
          <CircleArrowLeft size={23} color="#bbb" />
        </span>
      )}
      <div className="previous-next-content">{children}</div>
      {nextUrl !== undefined ? (
        <Link to={nextUrl}>
          <Tooltip tooltip={t('common.next')}>
            <CircleArrowRight size={23} color={circleColor} />
          </Tooltip>
        </Link>
      ) : (
        <span>
          <CircleArrowRight size={23} color="#bbb" />
        </span>
      )}
    </div>
  );
};

export default PreviousNextNavigation;
