import RFPResponse from 'types/api/RFPResponse';
import User from 'types/User';
import RFPSubmitResponseStatus from 'types/RFPSubmitResponseStatus';
import RFPResponseStatus from 'types/RFPResponseStatus';
import QuestionStatus from '../../types/QuestionStatus';
import TraroElementResponse from '../../types/api/TraroElementResponse';

export const isReviewerFromDifferentOrganization = (user: User, rfpResponse: RFPResponse) =>
  user.isReviewer && rfpResponse.organization.id !== user.organization.id;

export const isReviewerFromSameOrganization = (user: User, rfpResponse: RFPResponse) =>
  user.isReviewer && rfpResponse.organization.id === user.organization.id;

export const canSubmitAnswers = (user: User, rfpResponse: RFPResponse) => {
  if (user.isProponent) {
    return true;
  }

  if (isReviewerFromSameOrganization(user, rfpResponse)) {
    return true;
  }

  return false;
};

export const canEditProjectName = (user: User, rfpResponse: RFPResponse) => {
  if (isReviewerFromDifferentOrganization(user, rfpResponse)) {
    return false;
  }

  if (rfpResponse.rfp_submit_response_status === RFPSubmitResponseStatus.NO_SUBMISSIONS_REMAINING) {
    return false;
  }

  if ([RFPResponseStatus.FINAL, RFPResponseStatus.IN_REVIEW].includes(rfpResponse.rfp_response_status)) {
    return false;
  }

  return true;
};

export const canEditRfpResponseCloseDate = (user: User, rfpResponse: RFPResponse) => {
  if (!user.isReviewer) {
    return false;
  }

  if ([RFPResponseStatus.OPEN, RFPResponseStatus.IN_REVIEW].includes(rfpResponse.rfp_response_status)) {
    return true;
  }

  return false;
};

export const canEditRfpResponseICContact = (user: User, rfpResponse: RFPResponse) => {
  if (!user.isReviewer) {
    return false;
  }

  if ([RFPResponseStatus.OPEN, RFPResponseStatus.IN_REVIEW].includes(rfpResponse.rfp_response_status)) {
    return true;
  }

  return false;
};

export const canAnswerRFPQuestions = (
  user: User,
  rfpResponse: RFPResponse,
  rfpElementResponse: TraroElementResponse,
) => {
  if (isReviewerFromDifferentOrganization(user, rfpResponse)) {
    return false;
  }

  if (
    [
      QuestionStatus.DONE,
      QuestionStatus.REVIEW_ONLY,
      QuestionStatus.REVIEW_COMPLETE,
      QuestionStatus.NOT_REQUIRED,
    ].includes(rfpElementResponse.question_status)
  ) {
    return false;
  }

  return true;
};

export const canMarkAsReviewed = (user: User, rfpResponse: RFPResponse, rfpElementResponse: TraroElementResponse) => {
  if (isReviewerFromDifferentOrganization(user, rfpResponse)) {
    return false;
  }

  if (
    [QuestionStatus.REVIEW_ONLY, QuestionStatus.REVIEW_COMPLETE, QuestionStatus.NOT_REQUIRED].includes(
      rfpElementResponse.question_status,
    )
  ) {
    return false;
  }

  return true;
};

export const canReviewerMarkAsComplete = (
  user: User,
  rfpResponse: RFPResponse,
  rfpElementResponse: TraroElementResponse,
) => {
  if (!user.isReviewer) {
    return false;
  }

  return (
    rfpResponse.rfp_response_status === RFPResponseStatus.IN_REVIEW &&
    rfpElementResponse.question_status === QuestionStatus.REVIEW_ONLY
  );
};

export const canReviewerRevertToInReview = (
  user: User,
  rfpResponse: RFPResponse,
  rfpElementResponse: TraroElementResponse,
) => {
  if (!user.isReviewer) {
    return false;
  }

  return (
    rfpResponse.rfp_response_status === RFPResponseStatus.IN_REVIEW &&
    rfpElementResponse.question_status === QuestionStatus.REVIEW_COMPLETE
  );
};

export const canUpdateResponseStatus = (
  user: User,
  rfpResponse: RFPResponse,
  rfpElementResponse: TraroElementResponse,
) => {
  if (!user.isReviewer) {
    return false;
  }

  if (
    ![
      QuestionStatus.REVIEW_ONLY,
      QuestionStatus.UPDATE_REQUIRED,
      QuestionStatus.NOT_REQUIRED,
      QuestionStatus.REVIEW_COMPLETE,
    ].includes(rfpElementResponse.question_status)
  ) {
    return false;
  }

  if (rfpResponse.rfp_response_status !== RFPResponseStatus.IN_REVIEW) {
    return false;
  }

  return true;
};

export const canSubmitAssessment = (user: User, rfpResponse: RFPResponse, isComplete: boolean) => {
  if (!isComplete) {
    return false;
  }

  if (!canSubmitAnswers(user, rfpResponse)) {
    return false;
  }

  if ([RFPResponseStatus.IN_REVIEW, RFPResponseStatus.FINAL].includes(rfpResponse.rfp_response_status)) {
    return false;
  }

  return true;
};

export const canExportResponses = (user: User, rfpResponse: RFPResponse) => {
  if (user.isProponent) {
    return true;
  }

  if (user.organization.id === rfpResponse.organization.id) {
    return true;
  }

  if ([RFPResponseStatus.IN_REVIEW, RFPResponseStatus.FINAL].includes(rfpResponse.rfp_response_status)) {
    return true;
  }

  return false;
};

export const canSeeCriterionReviewRating = (user: User, elementResponse?: TraroElementResponse) => {
  if (user.isReviewer) {
    return true;
  }

  if (!elementResponse) {
    return false;
  }

  if (
    [QuestionStatus.BEGIN, QuestionStatus.DONE, QuestionStatus.IN_PROGRESS, QuestionStatus.REVIEW_ONLY].includes(
      elementResponse.question_status,
    )
  ) {
    return false;
  }

  return true;
};
