import React, { ComponentType, MouseEvent } from 'react';
import { flexRender, Row as TRow } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

export interface RowProps<TData> {
  row: TRow<TData>;
  onRowClick?: (row: TRow<TData>, event: MouseEvent) => void;
  getRowLink?: (row: TRow<TData>) => string | false;
  getRowClassName?: (row: TRow<TData>) => string;
  ExpandedRowComponent?: ComponentType<{ row: TRow<TData> }>;
}

const Row = <TData,>({ row, onRowClick, getRowLink, getRowClassName, ExpandedRowComponent }: RowProps<TData>) => {
  const link = getRowLink ? getRowLink(row) : false;

  return (
    <>
      <tr
        key={row.id}
        onClick={(event) => onRowClick?.(row, event)}
        className={`${getRowClassName?.(row)} ${row.getIsExpanded() ? 'expanded' : ''}`}
      >
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id} width={cell.column.getSize()}>
            {link ? (
              <Link className="td-inner-wrapper" to={link}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Link>
            ) : (
              <div className="td-inner-wrapper">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
            )}
          </td>
        ))}
      </tr>
      {row.getIsExpanded() && ExpandedRowComponent && (
        <tr key={row.id + 'expanded'} className="expanded-content">
          <td colSpan={row.getVisibleCells().length}>
            <ExpandedRowComponent row={row} />
          </td>
        </tr>
      )}
    </>
  );
};

export default Row;
