import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

import TraroElement from 'types/api/TraroElement';

import { useTabsContext } from 'components/Tabs/context/TabsContext';
import Note from 'components/Note/Note';
import Button from 'components/Button/Button';

import './ElementBackground.scss';

export interface ElementContextProps {
  traroElement: TraroElement;
}

const ElementBackground: FC<ElementContextProps> = ({ traroElement }) => {
  const { t } = useTranslation();
  const { setActiveKey } = useTabsContext();

  return (
    <div className="enkrateia-element-background">
      {traroElement.context0 && traroElement.context0 !== '' && (
        <ReactMarkdown linkTarget="_blank" className="context-0">
          {traroElement.context0}
        </ReactMarkdown>
      )}
      {traroElement.context1 && traroElement.context1 !== '' && (
        <ReactMarkdown linkTarget="_blank" className="context-1">
          {traroElement.context1}
        </ReactMarkdown>
      )}
      {traroElement.context2 && traroElement.context2 !== '' && (
        <Note>
          <ReactMarkdown linkTarget="_blank">{traroElement.context2}</ReactMarkdown>
        </Note>
      )}
      <Button variant="tertiary" onClick={() => setActiveKey('question')}>
        {t('pages.traroElement.continue')}
      </Button>
    </div>
  );
};

export default ElementBackground;
