import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TicketDetails from 'components/TicketDetails/TicketDetails';
import CreateTicketButton from 'components/CreateTicketButton/CreateTicketButton';
import Table from 'components/Table/Table';
import TicketDto from 'types/api/TicketDto';
import TraroElementResponse from 'types/api/TraroElementResponse';

import useColumns from './hooks/useColumns';

import './ElementTickets.scss';

export interface TicketsProps {
  tickets: TicketDto[] | undefined;
  rfpElementResponse: TraroElementResponse;
}

const ElementTickets: FC<TicketsProps> = ({ tickets, rfpElementResponse }) => {
  const columns = useColumns();
  const { t } = useTranslation();

  if (tickets?.length === 1) {
    return <TicketDetails ticketId={tickets[0].id} />;
  }

  if (tickets && tickets.length === 0) {
    return (
      <div className="enkrateia-response-tickets empty">
        <h3>{t('common.tickets.noTicketsForThisQuestion')}</h3>
        <p>{t('common.tickets.noTicketsForThisQuestionCta')}</p>
        <CreateTicketButton rfpRelated traroElementResponse={rfpElementResponse} />
      </div>
    );
  }

  return (
    <div className="enkrateia-response-tickets">
      <Table data={tickets} columns={columns} />
    </div>
  );
};

export default ElementTickets;
