import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useUser from 'hooks/useUser';
import { useSelector } from 'store';
import { useSingleRfpResponseStats } from 'api';
import { ArrowLeftIcon } from 'components/SvgIcons';
import CreateTicketButton from 'components/CreateTicketButton/CreateTicketButton';
import Announcement from 'components/Announcement/Announcement';
import SubmitAssessmentAction from 'components/SubmitAssessmentAction/SubmitAssessmentAction';

import KliparoSectionsList from './components/KliparoSectionsList/KliparoSectionsList';
import ReviewerActions from './components/ReviewerActions/ReviewerActions';

import './KliparoProjectOverview.scss';
import { canSubmitAssessment } from '../../../../services/permissions';

export interface KliparoProjectOverviewProps {}

const KliparoProjectOverview: FC<KliparoProjectOverviewProps> = () => {
  const { t } = useTranslation();
  const name = useSelector((store) => store.rfp.data.name);
  const description = useSelector((store) => store.rfp.data.rfp_description);
  const rfpResponse = useSelector((store) => store.rfpResponse.data);
  const { id: rfpResponseId } = rfpResponse;
  const user = useUser();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data: stats = { percent_complete: 0, open_ticket_count: 0 } } = useSingleRfpResponseStats(rfpResponseId);
  const isComplete = stats.percent_complete >= 100;
  const isSubmitVisible = canSubmitAssessment(user, rfpResponse, isComplete);

  return (
    <div className="enkrateia-kliparo-project-overview">
      <div className="head">
        <div className="title">
          <Link to="/dashboard" className="back-to-dashboard">
            <ArrowLeftIcon size={14} color="#05445E" />
            {t('pages.projectOverview.mainPanel.goBackToDashboard')}
          </Link>
          {isSubmitVisible && (
            <Announcement dismissible>
              <h2>{t('pages.projectOverview.mainPanel.submitAssessmentBannerTitle')}</h2>
              <p>{t('pages.projectOverview.mainPanel.submitAssessmentBannerText', { rfpName: name })}</p>
            </Announcement>
          )}
          <div className="head-content">
            <div className="description">
              <h1>{name}</h1>
              <ReactMarkdown linkTarget="_blank" className="context-0">
                {description}
              </ReactMarkdown>
            </div>
            <div className="actions">
              <CreateTicketButton rfpRelated />
              <SubmitAssessmentAction isComplete={isComplete} isSubmitVisible={isSubmitVisible} />
            </div>
          </div>
        </div>
      </div>
      {user.isReviewer && <ReviewerActions />}
      <div className="body">
        <KliparoSectionsList />
      </div>
    </div>
  );
};

export default KliparoProjectOverview;
