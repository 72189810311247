import { RefObject, useMemo } from 'react';

import { ElementScoreComponentWithResponse } from 'hooks/useElementScoresWithResponses/useElementScoresWithResponses';
import { FormikRichTextAreaValue } from 'components/forms/formik/FormikRichTextAreaInput/FormikRichTextAreaInput';
import { FormikProps } from 'formik/dist/types';

interface ElementScoreFormDataEntry extends Omit<ElementScoreComponentWithResponse, 'responseFeedback'> {
  responseFeedback: FormikRichTextAreaValue;
}

export type ElementScoreFormData = Record<number, ElementScoreFormDataEntry>;

const useInitialValues = (
  data: ElementScoreComponentWithResponse[],
  formRef: RefObject<FormikProps<ElementScoreFormData>>,
) =>
  useMemo(
    () =>
      data.reduce((acc, entry) => {
        const currentFormValue: ElementScoreFormDataEntry | undefined =
          formRef?.current?.getFieldMeta<ElementScoreFormDataEntry>(String(entry.elementScoreComponent.id)).value;

        return {
          ...acc,
          [entry.elementScoreComponent.id]: {
            ...entry,
            responseScore: currentFormValue?.responseScore ?? entry.responseScore,
            responseFeedback: {
              value: currentFormValue?.responseFeedback.value ?? entry.responseFeedback,
              editor: null,
            },
          },
        };
      }, {} as ElementScoreFormData),
    [data, formRef],
  );

export default useInitialValues;
