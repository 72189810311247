import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';

import FormSubmitError from 'errors/FormSubmitError';

import { useDispatch } from 'react-redux';
import QuestionStatus from 'types/QuestionStatus';

const setKliparoElementStatus = async (
  dispatch: ReturnType<typeof useDispatch>,
  rfpId: number,
  rfpResponseId: number,
  traroElementResponseId: number,
  status: QuestionStatus,
) => {
  try {
    const { updateKliparoRfpElementStatus } = await import('../../store/rfpResponse');
    await dispatch(
      // @ts-ignore
      updateKliparoRfpElementStatus({
        rfpId,
        rfpResponseId,
        traroElementResponseId,
        status,
      }),
    ).unwrap();
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const useUpdateKliparoRfpElementStatusMutation = (
  rfpId: number,
  rfpResponseId: number,
  traroElementResponseId: number,
) => {
  const dispatch = useDispatch();
  const mutate = useCallback(
    (status: QuestionStatus) => setKliparoElementStatus(dispatch, rfpId, rfpResponseId, traroElementResponseId, status),
    [dispatch, rfpId, rfpResponseId, traroElementResponseId],
  );

  return useMutation(mutate);
};

useUpdateKliparoRfpElementStatusMutation.queries = {
  setKliparoElementStatus,
} as const;

export default useUpdateKliparoRfpElementStatusMutation;
