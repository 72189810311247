import React from 'react';
import { EmailIcon } from '../SvgIcons';

import './MailtoIconLink.scss';

export interface MailtoIconLinkProps {
  email: string;
  size?: number;
  color?: string;
}

const MailtoIconLink = ({ email, size = 30, color = '#404040' }: MailtoIconLinkProps) => (
  <a className="enkrateia-mailto-icon-link" style={{ width: size, height: size }} href={`mailto:${email}`}>
    <EmailIcon size={size - 12} color={color} />
  </a>
);

export default MailtoIconLink;
