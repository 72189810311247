import React, { ReactNode, useMemo } from 'react';

export type BasicSliderMarkConfig = React.ReactNode;
export type ExtendedSliderMarkConfig = { mark: ReactNode; tooltip: ReactNode };
export type SliderMarkConfig = BasicSliderMarkConfig | ExtendedSliderMarkConfig;
export type SliderMarkConfigObject = Record<string | number, BasicSliderMarkConfig>;

function isExtendedSliderMarkConfig(entry: SliderMarkConfig): entry is ExtendedSliderMarkConfig {
  return entry !== undefined && entry !== null && typeof entry === 'object' && Object.hasOwn(entry, 'mark');
}

const useSliderMarksWithTooltips = (
  sliderMarks: Record<string | number, SliderMarkConfig> | undefined,
  min?: number,
  max?: number,
): [SliderMarkConfigObject, SliderMarkConfigObject] | [undefined, undefined] =>
  useMemo(() => {
    if (!sliderMarks) {
      if (min !== undefined && max !== undefined) {
        return [
          { [min]: min, [max]: max },
          { [min]: min, [max]: max },
        ];
      }
      return [undefined, undefined];
    }

    let newMarks: SliderMarkConfigObject = {};
    let newTooltips: SliderMarkConfigObject = {};

    Object.keys(sliderMarks).forEach((key) => {
      const markConfig = sliderMarks[key as string | number];

      if (isExtendedSliderMarkConfig(markConfig)) {
        newMarks[key] = markConfig.mark;
        newTooltips[key] = markConfig.tooltip;
      } else {
        newMarks[key] = markConfig;
        newTooltips[key] = markConfig;
      }
    });

    return [newMarks, newTooltips];
  }, [sliderMarks, min, max]);

export default useSliderMarksWithTooltips;
