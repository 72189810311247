import React, { FC, ReactNode } from 'react';
import { Props as ReactModalProps } from 'react-modal';

import { FilledXIcon } from '../../../SvgIcons';

import './KliparoModal.scss';

export interface KliparoModalProps {
  title: ReactNode;
  children: ReactNode;
  className?: string;
  onClose?: ReactModalProps['onRequestClose'];
}

const KliparoModal: FC<KliparoModalProps> = ({ children, className = '', title, onClose }) => (
  <div className={`enkrateia-kliparo-modal ${className}`}>
    <div className="modal-title">
      <h2>{title}</h2>
      <div>
        {onClose && (
          <button className="close-button" onClick={onClose}>
            <FilledXIcon size={24} color="#05445E" />
          </button>
        )}
      </div>
    </div>
    <div className="modal-content">{children}</div>
  </div>
);
export default KliparoModal;
