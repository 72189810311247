import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode } from 'react';

import useRequiredId from 'hooks/useRequiredId';
import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon } from 'components/SvgIcons';

import './TextInput.scss';

type Variant = 'standard' | 'filled';

export interface TextInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  error?: string | ReactNode;
  hint?: string | ReactNode;
  touched?: boolean;
  fullWidth?: boolean;
  variant?: Variant;
  required?: boolean;
  noLabel?: boolean;
  softMaxCharacters?: number;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      label,
      error,
      touched = false,
      required = false,
      fullWidth = false,
      className = '',
      type = 'text',
      variant = 'standard',
      hint,
      noLabel,
      softMaxCharacters = 0,
      ...props
    },
    ref,
  ) => {
    const ensuredId = useRequiredId(id);
    const errorId = `${ensuredId}-error`;

    const hasError = Boolean(error && error !== '' && touched);
    const hasCharacterCount = softMaxCharacters > 0;
    const characterCount = typeof props.value === 'number' ? `${props.value}`.length : props.value?.length || 0;

    return (
      <div
        className={`enkrateia-text-input ${className} variant-${variant} ${fullWidth ? 'full-width' : ''} ${
          hasError ? 'error' : ''
        }`}
      >
        <label htmlFor={ensuredId} className={noLabel ? 'sr-only' : ''}>
          {label}
          {required && '*'}
          {hint && (
            <Tooltip tooltip={hint}>
              <HelpIcon color="#05445E" size={25} />
            </Tooltip>
          )}
        </label>
        <div>
          <input {...props} aria-invalid={hasError} aria-errormessage={errorId} type={type} id={ensuredId} ref={ref} />
          {hasError && (
            <span id={errorId} className="error-label">
              {error}
            </span>
          )}
          {hasCharacterCount && (
            <span className="character-count">
              {characterCount}/{softMaxCharacters}
            </span>
          )}
        </div>
      </div>
    );
  },
);

export default TextInput;
