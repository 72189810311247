import { useCallback } from 'react';

import { useSelector } from 'store';
import QuestionStatus from 'types/QuestionStatus';
import { useUpdateKliparoRfpElementStatusMutation } from 'api/response';

const useReviewerActions = (rfpElementResponseId: number) => {
  const rfpId = useSelector((state) => state.rfp.data.id);
  const rfpResponseId = useSelector((state) => state.rfpResponse.data.id);

  const { mutateAsync, isLoading } = useUpdateKliparoRfpElementStatusMutation(
    rfpId,
    rfpResponseId,
    rfpElementResponseId,
  );

  const reviewerMarkAsComplete = useCallback(() => mutateAsync(QuestionStatus.REVIEW_COMPLETE), [mutateAsync]);

  const reviewerRevertToInReview = useCallback(() => mutateAsync(QuestionStatus.REVIEW_ONLY), [mutateAsync]);

  return {
    reviewerMarkAsComplete,
    reviewerRevertToInReview,
    isLoading,
  } as const;
};

export default useReviewerActions;
