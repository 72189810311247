import React, { ErrorInfo, FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Trans, useTranslation } from 'react-i18next';

import './ErrorOverlay.scss';
import Button from '../Button/Button';

export interface ErrorOverlayProps {
  className?: string;
  error?: string | Error | null;
  errorInfo?: ErrorInfo | null;
  onClose?: () => void;
  inLine?: boolean;
}

const ErrorOverlay: FC<ErrorOverlayProps> = ({ className = '', inLine = false, error, errorInfo, onClose }) => {
  const { t } = useTranslation();

  return (
    <CSSTransition in={!!error} mountOnEnter unmountOnExit classNames="fade" timeout={300}>
      <div className={`enkrateia-error-overlay ${className} ${inLine ? 'in-line' : 'absolute'}`}>
        <div className="error-content">
          <h1>{t('errors.oops')}</h1>
          <h4>{t('errors.somethingWentWrong')}</h4>
          <p>
            <Trans i18nKey="errors.tryAgainAfterError">
              Please contact <a href={t('errors.tryAgainAfterErrorUrl')}>support@lydion.com</a>!
            </Trans>
          </p>
          <details>
            <>
              <br />
              <p>{typeof error === 'string' ? error : error?.toString()}</p>
              <pre>{errorInfo && errorInfo.componentStack}</pre>
            </>
          </details>
          {onClose && (
            <Button variant="default" onClick={onClose}>
              {t('errors.close')}
            </Button>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};
export default ErrorOverlay;
