import React, { FC, useCallback } from 'react';
import ArchElementSpecific from 'types/ArchElementSpecific';
import { reset } from 'store/arch';
import { useDispatch } from 'store';

import Voussoir from './components/Voussoir/Voussoir';
import Springer from './components/Springer/Springer';
import Keystone from './components/Keystone/Keystone';
import Text from './components/Text/Text';
import KeystoneText from './components/KeystoneText/KeystoneText';
import CompletionStatus from './components/CompletionStatus/CompletionStatus';

import './TraroArch.scss';
import Background from './components/Background/Background';
import BackgroundText from './components/BackgroundText/BackgroundText';

export interface TraroArchProps {}

const TraroArch: FC<TraroArchProps> = () => {
  const dispatch = useDispatch();
  const unselect = useCallback(() => dispatch(reset()), [dispatch]);

  return (
    <button type="button" className="enkrateia-arch" onClick={unselect}>
      <svg viewBox="0 0 1085 360" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={unselect}>
        <KeystoneText x="50%" y={7} />

        <Springer x={273} y={245} springer={ArchElementSpecific.SPRINGER_LEFT} />
        <Voussoir x={301} y={144} voussoir={ArchElementSpecific.VOUSSOIR_LEFT_1} />
        <Voussoir x={381} y={79} voussoir={ArchElementSpecific.VOUSSOIR_LEFT_2} />
        <Voussoir x={653} y={144} voussoir={ArchElementSpecific.VOUSSOIR_RIGHT_1} />
        <Voussoir x={587} y={79} voussoir={ArchElementSpecific.VOUSSOIR_RIGHT_2} />
        <Springer x={692} y={245} springer={ArchElementSpecific.SPRINGER_RIGHT} />

        <Keystone x={485} y={40} />

        {/*<Brick text="01" id={ArchElementSpecific.BRICK_1} x={210.5} y={43.5} />*/}
        {/*<Brick text="02" id={ArchElementSpecific.BRICK_2} x={323.5} y={43.5} />*/}
        {/*<Brick text="03" id={ArchElementSpecific.BRICK_3} x={668} y={43.5} />*/}
        {/*<Brick text="04" id={ArchElementSpecific.BRICK_4} x={781} y={43.5} />*/}
        {/*<Brick text="05" id={ArchElementSpecific.BRICK_5} x={161.5} y={86.5} />*/}
        {/*<Brick text="06" id={ArchElementSpecific.BRICK_6} x={274.5} y={86.5} />*/}
        {/*<Brick text="07" id={ArchElementSpecific.BRICK_7} x={717} y={86.5} />*/}
        {/*<Brick text="08" id={ArchElementSpecific.BRICK_8} x={830} y={86.5} />*/}
        {/*<Brick text="09" id={ArchElementSpecific.BRICK_9} x={210.5} y={129.5} />*/}
        {/*<Brick text="10" id={ArchElementSpecific.BRICK_10} x={781} y={129.5} />*/}

        <Text id={ArchElementSpecific.VOUSSOIR_LEFT_2} x={0} y={80} align="end" width={400} />
        <Text id={ArchElementSpecific.VOUSSOIR_LEFT_1} x={0} y={170} align="end" width={290} />
        <Text id={ArchElementSpecific.SPRINGER_LEFT} x={0} y={280} align="end" />

        <Text id={ArchElementSpecific.VOUSSOIR_RIGHT_2} x={830 - 140} y={80} align="start" width={400} />
        <Text id={ArchElementSpecific.VOUSSOIR_RIGHT_1} x={830 - 40} y={170} align="start" width={290} />
        <Text id={ArchElementSpecific.SPRINGER_RIGHT} x={830} y={280} align="start" />

        <Background x={467} y={290} />
        <BackgroundText x="50%" y={325} />

        <CompletionStatus x="50%" y="260" />
      </svg>
    </button>
  );
};

export default TraroArch;
