import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import { DateTime } from 'luxon';
import 'react-day-picker/dist/style.css';

import useRequiredId from 'hooks/useRequiredId';
import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon, TimesIcon } from 'components/SvgIcons';
import PopperWithFocusTrap from 'components/PopperWIthFocusTrap/PopperWithFocusTrap';
import useFormattedDate, { DEFAULT_DATE_FORMAT } from 'hooks/useFormattedDate';

import './DatePickerInput.scss';

type Variant = 'standard' | 'filled' | 'raw';

export interface DatePickerInputProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' | 'onChange'> {
  label?: string;
  error?: string | ReactNode;
  hint?: string | ReactNode;
  touched?: boolean;
  fullWidth?: boolean;
  variant?: Variant;
  required?: boolean;
  value: DateTime | null;
  noLabel?: boolean;
  onChange: (value: DateTime | null) => void;
  clearable?: boolean;
  format?: string;
}

const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(
  (
    {
      id,
      label,
      error,
      touched = false,
      required = false,
      fullWidth = false,
      className = '',
      type = 'text',
      variant = 'standard',
      hint,
      value,
      onChange,
      noLabel,
      clearable,
      onFocus: originalOnFocus,
      format = DEFAULT_DATE_FORMAT,
      ...props
    },
    ref,
  ) => {
    const localRef = useRef<HTMLButtonElement>(null);
    const reOpenBlocker = useRef(false);

    const [open, setOpen] = useState(false);
    const ensuredId = useRequiredId(id);
    const errorId = `${ensuredId}-error`;

    const hasError = Boolean(error && error !== '' && touched);

    const handleDialogClose = useCallback(() => {
      reOpenBlocker.current = true;
      setOpen(false);

      setTimeout(() => {
        reOpenBlocker.current = false;
      }, 100);
    }, [reOpenBlocker]);

    const handleDialogOpen = useCallback(() => {
      if (!reOpenBlocker.current) {
        setOpen(true);
      }
    }, [reOpenBlocker]);

    const onSelect: SelectSingleEventHandler = useCallback(
      (day) => {
        onChange(!day ? null : DateTime.fromJSDate(day));
        handleDialogClose();
      },
      [onChange, handleDialogClose],
    );
    const formattedDate = useFormattedDate(value?.toISODate() ?? '', format);

    const onFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        handleDialogOpen();

        if (originalOnFocus) {
          originalOnFocus(event);
        }
      },
      [originalOnFocus, handleDialogOpen],
    );

    const [popperReferenceElement, setPopperReferenceElement] = useState<HTMLDivElement | null>(null);

    return (
      <div
        className={`enkrateia-date-picker-input ${className} variant-${variant} ${fullWidth ? 'full-width' : ''}`}
        ref={setPopperReferenceElement}
      >
        {!noLabel && (
          <label htmlFor={ensuredId}>
            {label}
            {required && '*'}
            {hint && (
              <Tooltip tooltip={hint}>
                <HelpIcon color="#05445E" size={25} />
              </Tooltip>
            )}
          </label>
        )}
        <div>
          <input
            {...props}
            value={formattedDate}
            readOnly
            onFocus={onFocus}
            onClick={handleDialogOpen}
            aria-invalid={hasError}
            aria-errormessage={errorId}
            type={type}
            id={ensuredId}
            ref={ref}
          />
          {hasError && (
            <span id={errorId} className="error-label">
              {error}
            </span>
          )}
          {clearable && !props.disabled && value !== null && (
            <button className="clear-icon" onClick={() => onChange(null)}>
              <TimesIcon color="#E4E4E4" size={12} />
            </button>
          )}
        </div>
        <PopperWithFocusTrap
          open={open}
          referenceElement={popperReferenceElement}
          popperElementClassName="date-picker"
          focusTrapOptions={{
            initialFocus: false,
            onDeactivate: handleDialogClose,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            fallbackFocus: localRef.current ?? undefined,
          }}
        >
          <DayPicker
            initialFocus={open}
            mode="single"
            showOutsideDays
            selected={value?.toJSDate()}
            onSelect={onSelect}
          />
        </PopperWithFocusTrap>
      </div>
    );
  },
);

export default DatePickerInput;
