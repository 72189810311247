import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import FormikTextAreaInput from 'components/forms/formik/FormikTextAreaInput/FormikTextAreaInput';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import FormikUploadAttachment from 'components/forms/formik/FormikUploadAttachment/FormikUploadAttachment';
import TicketDto from 'types/api/TicketDto';
import TicketStatus from 'types/TicketStatus';

import TogglePublishFlag from './components/TogglePublishFlag/TogglePublishFlag';
import AddTicketButton from './components/AddTicketButton/AddTicketButton';
import Comment from './components/Comment/Comment';
import useInitialValues from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';
import useTicketSubmit from './hooks/useTicketCommentSubmit';

import './TicketComments.scss';
import { DateTime } from 'luxon';

export interface TicketCommentsProps {
  ticket: TicketDto;
  noHeader?: boolean;
  noAttachments?: boolean;
}

const TicketComments: FC<TicketCommentsProps> = ({ ticket, noHeader = false, noAttachments = false }) => {
  const { t } = useTranslation();
  const initialValues = useInitialValues(ticket.id);
  const schema = useSchema();
  const [onSubmit, isLoading] = useTicketSubmit();
  const sortedTicketComments = useMemo(
    () =>
      ticket.ticket_comment_list.sort(
        (a, b) => DateTime.fromISO(a.comment_time).toSeconds() - DateTime.fromISO(b.comment_time).toSeconds(),
      ),
    [ticket.ticket_comment_list],
  );

  return (
    <div className="enkrateia-ticket-comments">
      {!noHeader && (
        <section className="header">
          <div className="max-width">
            <h2>{t('pages.ticketDetails.comments.title')}</h2>
          </div>
        </section>
      )}
      <section className="comments">
        <ErrorBoundary inLine>
          {sortedTicketComments.map((comment) => (
            <Comment key={`${comment.commenter.id}-${comment.comment_time}`} comment={comment} />
          ))}
        </ErrorBoundary>
      </section>
      {ticket.ticket_status === TicketStatus.OPEN && (
        <section className="comment-form">
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
            <Form>
              <div className="comment-and-attachment">
                <FormikTextAreaInput name="ticketComment" variant="filled" label="" rows={4} autoHeight />
                <FormikUploadAttachment.Display name="attachment" clearable />
              </div>
              <div className="buttons">
                {!noAttachments && <FormikUploadAttachment name="attachment" />}
                <TogglePublishFlag />
                <AddTicketButton />
              </div>
            </Form>
          </Formik>
        </section>
      )}
      <LoaderOverlay open={isLoading} />
    </div>
  );
};

export default TicketComments;
