import React, { FC } from 'react';
import { Row } from '@tanstack/react-table';

import TicketWithConnection from 'types/TicketWithConnection';
import LinkWithIcon from 'components/LinkWithIcon/LinkWithIcon';
import useLinkedElementUrl from 'hooks/useLinkedElementUrl/useLinkedElementUrl';

export interface LinkedElementCellProps {
  row: Row<TicketWithConnection>;
}

const LinkedElementCell: FC<LinkedElementCellProps> = ({ row }) => {
  const value = row.original.connection;
  const url = useLinkedElementUrl(value);

  if (!value) {
    return <span>-</span>;
  }

  return (
    <LinkWithIcon to={url} color="#4A4A4A">
      {value.element_name}
    </LinkWithIcon>
  );
};

export default LinkedElementCell;
