import React from 'react';
import { useTranslation } from 'react-i18next';

import useElementScoresWithResponses from 'hooks/useElementScoresWithResponses/useElementScoresWithResponses';

import CollapsibleSection from 'components/CollapsibleSection/CollapsibleSection';
import { Table } from 'components/Table';
import Panel from 'components/Panel/Panel';
import TraroElementResponse from 'types/api/TraroElementResponse';
import TraroElement from 'types/api/TraroElement';
import QuestionResponse from 'components/QuestionResponse/QuestionResponse';

import './ArchElementOverview.scss';
import useColumns from './hooks/useColumns';
import { Form, Formik } from 'formik';
import useKliparoElementForm from '../../../../components/QuestionResponse/hooks/useKliparoElementForm';
export interface ArchElementOverviewProps {
  traroElement: TraroElement;
  traroElementResponse: TraroElementResponse;
}

const doNothing = () => {};

const ArchElementOverview = ({ traroElement, traroElementResponse }: ArchElementOverviewProps) => {
  const { t } = useTranslation();
  const columns = useColumns();
  const elementScoresWithResponses = useElementScoresWithResponses(traroElement, traroElementResponse);
  const { initialValues } = useKliparoElementForm({
    traro_element: traroElement,
    rfpElementResponse: traroElementResponse,
  });

  return (
    <CollapsibleSection
      title={`${traroElement.element_number} ${traroElement.element_name}`}
      defaultExpanded
      className="enkrateia-condensed-arch-element-overview"
    >
      <Panel className="score">
        <h3>{t('pages.projectOverview.mainPanel.condensedView.archScore')}:</h3>
        <span>
          {traroElementResponse.question_score} / {traroElement.max_score}
        </span>
      </Panel>
      <div className="row">
        <Panel>
          <h3>{t('pages.projectOverview.mainPanel.condensedView.archQuestionsAndResponses')}</h3>
          <Formik initialValues={initialValues} onSubmit={doNothing} enableReinitialize>
            <Form>
              <QuestionResponse
                traroElement={traroElement}
                rfpElementResponse={traroElementResponse}
                readOnly
                noHeader
              />
            </Form>
          </Formik>
        </Panel>
        <Panel className="scores-and-feedback">
          <h3>{t('pages.projectOverview.mainPanel.condensedView.scoresAndFeedback')}</h3>
          <Table variant="no-border" columns={columns} data={elementScoresWithResponses} />
        </Panel>
      </div>
    </CollapsibleSection>
  );
};

export default ArchElementOverview;
