import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Button from 'components/Button/Button';
import LinkButton from 'components/LinkButton/LinkButton';
import { OutlinedPlusIcon } from 'components/SvgIcons';
import useUser from 'hooks/useUser';

import RecentTickets from './components/RecentTickets/RecentTickets';
import Assessments from './components/Assessments/Assessments';
import NewAssessmentModal from './components/NewAssessmentModal/NewAssessmentModal';
import DashboardMessages from './components/DashboardMessages/DashboardMessages';
import TextFit from './components/TextFit/TextFit';

import './KliparoDashboard.scss';

export interface ProponentDashboardProps {}

const KliparoDashboard: FC<ProponentDashboardProps> = () => {
  const { t } = useTranslation();
  const [createAssessmentModalOpen, setCreateAssessmentModalOpen] = useState(false);
  const { first_name, last_name, organization, isReviewer } = useUser();

  return (
    <div className="enkrateia-kliparo-dashboard">
      <Header />
      <div className="content">
        <div className="actions">
          <Button variant="primary" onClick={() => setCreateAssessmentModalOpen(true)}>
            <span className="link">
              <OutlinedPlusIcon color="#000000" size={20} />
              {isReviewer
                ? t('pages.dashboard.links.createAssessment')
                : t('pages.dashboard.links.createProgramLevelAssessment')}
            </span>
          </Button>
          <LinkButton variant="tertiary-filled" to="/organization">
            <span className="link">{t('pages.dashboard.links.companyProfile')}</span>
            <TextFit>{organization.name}</TextFit>
          </LinkButton>
          <LinkButton variant="dark-green-filled" to="/organization">
            <span className="link">{t('pages.dashboard.links.myProfile')}</span>
            <TextFit>{`${first_name} ${last_name}`}</TextFit>
          </LinkButton>
        </div>
        <DashboardMessages />
        <Assessments />
        {!isReviewer && <RecentTickets />}
        <NewAssessmentModal
          isOpen={createAssessmentModalOpen}
          onRequestClose={() => setCreateAssessmentModalOpen(false)}
        />
      </div>
      <Footer />
    </div>
  );
};

export default KliparoDashboard;
