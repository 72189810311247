import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';

import { TicketCommentFormState } from '../../hooks/useInitialValues';

const AddTicketButton = () => {
  const { t } = useTranslation();
  const { values, submitForm } = useFormikContext<TicketCommentFormState>();

  const variant = values.published ? 'black-filled' : 'primary';
  const status = values.published ? 'enabled' : 'disabled';

  return (
    <Button variant={variant} onClick={() => submitForm()}>
      {t(`pages.ticketDetails.publishedComment.enterComment.${status}`)}
    </Button>
  );
};

export default AddTicketButton;
