import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import UserDto from 'types/api/UserDto';
import useUser from 'hooks/useUser';
import useICContact from 'hooks/useICContact/useICContact';
import ProjectInfoPanel from 'components/ProjectInfoPanel/ProjectInfoPanel';
import EditableProjectInfoPanel from 'components/EditableProjectInfoPanel/EditableProjectInfoPanel';
import SelectInput, { InputOption } from 'components/forms/raw/SelectInput';
import useCurrentOrganizationUsers from 'api/organization/useCurrentOrganizationUsers';

export interface IcContactProps {
  onPointOfContactChange: (newPointOfContact: UserDto | null) => Promise<void>;
  disabled?: boolean;
}

const IcContact = ({ onPointOfContactChange, disabled }: IcContactProps) => {
  const { t } = useTranslation();
  const { isReviewer } = useUser();
  const [contactEmail, contactName, ICContactUser] = useICContact();
  const { data: users = [] } = useCurrentOrganizationUsers();
  const selectableUsers = useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
        meta: user,
      })),
    [users],
  );

  if (!isReviewer) {
    return (
      <ProjectInfoPanel
        variant="traro"
        label={t('pages.projectOverview.sidePanel.ICContact')}
        value={<a href={`mailto:${contactEmail}`}>{contactName}</a>}
      />
    );
  }

  return (
    <EditableProjectInfoPanel<InputOption<number, UserDto> | null>
      variant="kliparo"
      label={t('pages.projectOverview.sidePanel.ICContact')}
      value={ICContactUser}
      className="span-2-column"
      disabled={disabled}
      onSubmit={(value) => onPointOfContactChange(value?.meta ?? null)}
      InputComponent={({ innerRef, ...props }) => (
        <SelectInput {...props} innerRef={innerRef as any} options={selectableUsers} variant="raw" />
      )}
      areValuesEqual={(a, b) => a?.value === b?.value}
    />
  );
};

export default IcContact;
