import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon } from 'components/SvgIcons';

import useKliparoSectionsWithResponses from './hooks/useKliparoSectionsWithResponses';
import KliparoSectionRow from './components/KliparoSectionRow/KliparoSectionRow';
import RatingHelp from './components/RatingHelp/RatingHelp';
import ResponseStatusHelp from './components/ResponseStatusHelp/ResponseStatusHelp';

import './KliparoSectionsList.scss';

export interface KliparoSectionsListProps {}

const KliparoSectionsList: FC<KliparoSectionsListProps> = ({}) => {
  const { t } = useTranslation();
  const sections = useKliparoSectionsWithResponses();

  return (
    <div className="enkrateia-list-project-overview kliparo-sections-list">
      <h2>{t('pages.projectOverview.mainPanel.kliparoTitle')}</h2>
      <table>
        <thead>
          <tr>
            <th className="center number"></th>
            <th>{t('pages.projectOverview.mainPanel.table.header.section')}</th>
            <th className="center review-rating">
              <div>
                {t('pages.projectOverview.mainPanel.table.header.rating')}
                <RatingHelp />
              </div>
            </th>
            <th className="center tickets">
              <div>
                {t('pages.projectOverview.mainPanel.table.header.tickets')}
                <Tooltip tooltip={t('pages.projectOverview.mainPanel.table.header.ticketsHelp')}>
                  <HelpIcon size={24} color="#05445E" />
                </Tooltip>
              </div>
            </th>
            <th className="center response-status">
              <div>
                {t('pages.projectOverview.mainPanel.table.header.responseStatus')}
                <ResponseStatusHelp />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {sections.map((section) => (
            <KliparoSectionRow section={section} key={section.section_number} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default KliparoSectionsList;
