import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import QuestionStatusButton from 'components/QuestionStatusButton/QuestionStatusButton';
import QuestionStatus from 'types/QuestionStatus';
import ArchElementGeneric from 'types/ArchElementGeneric';
import { useDispatch, useSelector } from 'store';
import { openModal } from 'store/modals';

import helpIcon from 'assets/img/helpIcon.svg';
import commentIcon from 'assets/img/commentIcon.png';

import './ListOverview.scss';

export interface ListOverviewProps {}

const ListOverview: FC<ListOverviewProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const rfpId = useSelector((store) => store.rfp.data.id);
  const rfpResponseId = useSelector((store) => store.rfpResponse.data.id);
  const traroElements = useSelector((store) => store.rfp.data.traro_element_list);
  const traroResponses = useSelector((store) => store.rfpResponse.data.traro_element_response_list);

  const elementWithResponses = useMemo(
    () =>
      traroElements
        .filter((element) => element.arch_element_generic !== ArchElementGeneric.BRICK)
        .map((element) => {
          const response = traroResponses.find((r) => r.arch_element_specific === element.arch_element_specific);

          return [element, response] as const;
        })
        .sort((a, b) => a[0].element_number - b[0].element_number),
    [traroElements, traroResponses],
  );

  const openQuestionStatusHelp = useCallback(() => {
    dispatch(openModal({ modal: 'questionStatusesHelpModal', data: undefined }));
  }, [dispatch]);

  return (
    <div className="enkrateia-list-project-overview">
      <h2>{t('pages.projectOverview.mainPanel.title')}</h2>
      <table>
        <thead>
          <tr>
            <th className="center number">{t('pages.projectOverview.mainPanel.table.header.number')}</th>
            <th>{t('pages.projectOverview.mainPanel.table.header.section')}</th>
            <th className="center">{t('pages.projectOverview.mainPanel.table.header.score')}</th>
            <th className="center">{t('pages.projectOverview.mainPanel.table.header.comments')}</th>
            <th className="center status">
              <button type="button" onClick={openQuestionStatusHelp}>
                <img src={helpIcon} alt="help" />
                {t('pages.projectOverview.mainPanel.table.header.questionStatus')}
              </button>
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {elementWithResponses.map(([element, response]) => (
            <tr key={`${element.element_number}.${element.element_name}`}>
              <td className="center number">{element.element_number}</td>
              <td className="name">{element.element_name}</td>
              <td className="center light score">
                {response?.question_score ?? 0}/{element.max_score}
              </td>
              <td className="center light review">
                <span>
                  {response?.traro_element_comment?.length} <img src={commentIcon} alt="comment" />
                </span>
              </td>
              <td className="center status">
                <QuestionStatusButton
                  status={response?.question_status ?? QuestionStatus.BEGIN}
                  onClick={() =>
                    navigate(
                      `/rfp/${rfpId}/rfp-response/${rfpResponseId}/rfp-element-response/${element.arch_element_specific}`,
                    )
                  }
                />
              </td>
              <td className="filler">&nbsp;</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListOverview;
