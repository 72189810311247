import React, { FC } from 'react';
import ArchElementSpecific from 'types/ArchElementSpecific';
import { isCompleted, isUpdateRequired } from 'store/rfpResponse/helpers';

import CheckedCircle from '../CheckedCircle/CheckedCircle';
import ExclamationCircle from '../ExclamationCircle/ExclamationCircle';

import { useArchContextForElement } from '../../../../context/ArchContext';
import useElementSelection from '../../../../hooks/useElementSelection';

import useBackgroundCheckedCirclePositioner from './hooks/useBackgroundCheckedCirclePositioner';

import './BackgroundText.scss';

export interface KeystoneTextProps {
  x: number | string;
  y: number;
}

const BackgroundText: FC<KeystoneTextProps> = ({ x, y }) => {
  const { element, response } = useArchContextForElement(ArchElementSpecific.BACKGROUND);
  const [isSelected, select] = useElementSelection(ArchElementSpecific.BACKGROUND);
  const [circleRef, textRef] = useBackgroundCheckedCirclePositioner(response);

  return (
    <g className="enkrateia-arch-background-text" opacity={isSelected ? 1 : 0.3} onClick={select} cursor="pointer">
      {isCompleted(response) && <CheckedCircle ref={circleRef} x={x} y={y} />}
      {isUpdateRequired(response) && <ExclamationCircle ref={circleRef} x={x} y={y} />}
      <text x={x} y={y + 18} fill="#fff" textAnchor="middle" ref={textRef}>
        {element.element_name}
      </text>
    </g>
  );
};

export default BackgroundText;
