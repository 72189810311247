import { useQuery } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import GlossaryEntryDto from 'types/api/GlossaryEntryDto';

export const fetchGlossaryEntries = async (): Promise<GlossaryEntryDto[]> => {
  try {
    return (await API.get<GlossaryEntryDto[]>(`/v2/glossary/`)).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = () => ['settings/glossaryEntries'];

const useOrganizations = () => useQuery(getQueryKey(), fetchGlossaryEntries);

useOrganizations.getQueryKey = getQueryKey;
useOrganizations.queries = {
  fetchGlossaryEntries,
} as const;

export default useOrganizations;
