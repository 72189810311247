import { ElementScoreFormData } from './useInitialValues';

const shouldReSaveAfterAutoSave = (preSaveValues: ElementScoreFormData, postSaveValues: ElementScoreFormData) => {
  const keys = Object.keys(preSaveValues) as unknown as (keyof ElementScoreFormData)[];

  for (const key of keys) {
    if (
      preSaveValues[key].responseFeedback.value !== postSaveValues[key].responseFeedback.value ||
      preSaveValues[key].responseScore !== postSaveValues[key].responseScore
    ) {
      return true;
    }
  }

  return false;
};

const useShouldReSaveAfterAutoSave = () => shouldReSaveAfterAutoSave;

export default useShouldReSaveAfterAutoSave;
