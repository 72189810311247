import { useSelector } from 'store';
import RFPResponseStatus from 'types/RFPResponseStatus';
import QuestionStatus from 'types/QuestionStatus';

const useIsFeedbackDisabled = (rfpElementResponse: { question_status: QuestionStatus }) => {
  const isResponseInReview = useSelector(
    (state) => state.rfpResponse.data.rfp_response_status === RFPResponseStatus.IN_REVIEW,
  );

  return !isResponseInReview || rfpElementResponse.question_status === QuestionStatus.REVIEW_COMPLETE;
};

export default useIsFeedbackDisabled;
