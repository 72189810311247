import TicketDto from 'types/api/TicketDto';
import TicketSourceType from 'types/TicketSourceType';
import { useMemo } from 'react';

const useTicketsFromCategory = (tickets: TicketDto[], category: TicketSourceType): TicketDto[] =>
  useMemo(() => {
    const categories: (TicketSourceType | null)[] =
      category === TicketSourceType.SOURCE_NOT_ASSIGNED ? [category, null] : [category];

    return tickets.filter((ticket) => categories.includes(ticket.source));
  }, [tickets, category]);

export default useTicketsFromCategory;
