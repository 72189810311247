import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckInCircleIcon, SyncIcon } from 'components/SvgIcons';

import { useAutoSaveContext } from '../../AutoSaveWatcher.context';

import './AutoSaveIndicator.scss';

const AutoSaveIndicator = () => {
  const { t } = useTranslation();
  const { loading, disabled } = useAutoSaveContext();

  return (
    <div className={`enkrateia-auto-save-indicator ${loading ? 'saving' : 'not-saving'}`}>
      {loading && !disabled ? (
        <SyncIcon size={12} color="#999" className="syncing" />
      ) : (
        <CheckInCircleIcon size={12} color="#179817" />
      )}
      {t(loading ? 'autoSave.saving' : 'autoSave.saved')}
    </div>
  );
};

export default AutoSaveIndicator;
