import { useMemo } from 'react';
import { sortBy } from 'lodash';

import TraroElement from 'types/api/TraroElement';
import QuestionStatus from 'types/QuestionStatus';
import TraroElementResponse from 'types/api/TraroElementResponse';
import ElementScoreComponent from 'types/api/ElementScoreComponent';

export interface ElementScoreComponentWithResponse {
  elementScoreComponent: ElementScoreComponent;
  responseScore: number | '';
  responseFeedback: string;
  question_status: QuestionStatus;
}

const useElementScoresWithResponses = (
  traroElement: TraroElement,
  rfpElementResponse: TraroElementResponse,
): ElementScoreComponentWithResponse[] =>
  useMemo(
    () =>
      sortBy(traroElement.element_score_components, 'display_order').map((elementScoreComponent) => {
        const traroElementScore = rfpElementResponse.traro_element_score.find(
          (tes) => tes.element_score_component === elementScoreComponent.id,
        );

        return {
          elementScoreComponent,
          responseScore: traroElementScore?.question_score ?? '',
          responseFeedback: traroElementScore?.element_feedback ?? '',
          question_status: rfpElementResponse.question_status,
        };
      }),
    [traroElement, rfpElementResponse],
  );

export default useElementScoresWithResponses;
