import { useMutation, useQueryClient } from '@tanstack/react-query';

import API, { ApiResponse } from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';

import { useRecentTickets, useRfpElementTickets, useTicket, useTicketConnectedElement, useTickets } from '../ticket';
import TicketDto from '../../types/api/TicketDto';

interface SaveTicketParams<T = any> {
  ticket: Omit<TicketDto, 'assigned_user'> & { assigned_user: number };
  meta?: T;
}

interface SaveTicketResponse extends ApiResponse {
  ticket_list: [TicketDto];
}

export const saveTicket = async ({ ticket }: SaveTicketParams) => {
  try {
    if (ticket.traro_element_response_id === null) {
      delete ticket.traro_element_response_id;
    }

    return (await API.post<SaveTicketResponse>('/ticket-list/', { ticket_list: [ticket] })).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

interface UseSaveTicketMutationParams<T> {
  onSuccess?: (variables: SaveTicketParams<T>) => Promise<void> | void;
  onError?: (error: Error, variables: SaveTicketParams<T>) => Promise<void> | void;
}

const useSaveTicketMutation = <T = any>({ onSuccess, onError }: UseSaveTicketMutationParams<T> = {}) => {
  const queryClient = useQueryClient();

  return useMutation<SaveTicketResponse, FormSubmitError, SaveTicketParams<T>>(saveTicket, {
    onSuccess: async (data, variables) => {
      for (const value of [
        useTicket.getQueryKey(data.ticket_list[0].id),
        useRfpElementTickets.getQueryKey(),
        useRecentTickets.getQueryKey(),
        useTickets.getQueryKey(),
        useTicketConnectedElement.getQueryKey(data.ticket_list[0].id),
      ]) {
        await queryClient.invalidateQueries({ queryKey: value });
        await queryClient.refetchQueries({ queryKey: value });
      }

      if (onSuccess) {
        await onSuccess(variables);
      }
    },
    onError: async (error: Error, variables) => {
      if (onError) {
        await onError(error, variables);
      }
    },
  });
};

useSaveTicketMutation.queries = {
  saveTicket,
} as const;

export default useSaveTicketMutation;
