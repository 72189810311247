import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { ElementScoreComponentWithResponse } from 'hooks/useElementScoresWithResponses/useElementScoresWithResponses';
import { RawHTMLCell } from 'components/Table';

const useColumns = (): ColumnDef<ElementScoreComponentWithResponse, any>[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columnHelper = createColumnHelper<ElementScoreComponentWithResponse>();

    return [
      columnHelper.accessor('elementScoreComponent.evaluation_criteria', {
        header: t('pages.projectOverview.mainPanel.condensedView.headers.evalCriteria'),
        size: 5,
      }),
      columnHelper.accessor('responseScore', {
        header: t('pages.projectOverview.mainPanel.condensedView.headers.score'),
        size: 5,
      }),
      columnHelper.accessor('responseFeedback', {
        header: t('pages.projectOverview.mainPanel.condensedView.headers.feedback'),
        size: 500,
        cell: RawHTMLCell,
      }),
    ];
  }, [t]);
};

export default useColumns;
