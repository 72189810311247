import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';

import { fetchGlossaryEntries } from 'api/settings/useGlossaryEntries';
import GlossaryEntryDto from 'types/api/GlossaryEntryDto';

import en from './en.json';

const setDeepKey = (target: Record<string, unknown>, key: string, value: unknown) => {
  const keys = key.split('.');
  const lastKey = keys.pop() as string;

  keys.forEach((k) => {
    if (!target[k]) {
      target[k] = {};
    }

    target = target[k] as Record<string, unknown>;
  });

  target[lastKey] = value;
};

const backendOptions: HttpBackendOptions = {
  parse: (data) => {
    const glossary = {
      ...en,
    };

    (JSON.parse(data) as GlossaryEntryDto[]).forEach((entry) => {
      setDeepKey(glossary, entry.key, entry.value);
    });

    return glossary;
  },
  request: (options, url, payload, callback) => {
    fetchGlossaryEntries()
      .then((entries) => JSON.stringify(entries))
      .catch(() => '[]')
      .then((entries) => {
        callback(null, { status: 200, data: entries });
      });
  },
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    returnNull: false,
    backend: backendOptions,
  });

if (window.appEnv === 'dev' || window.appEnv === '{{APP_ENV}}') {
  window.toggleI18NDevMode = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'cimode' : 'en');
  };
}

export default i18n;
