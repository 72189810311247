import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ArrowLeftIcon } from 'components/SvgIcons';

import './GoBackPanel.scss';

export interface GoBackPanelProps {
  target: string;
  label: string;
  variant: 'sidebar' | 'plain';
}

const GoBackPanel: FC<GoBackPanelProps> = ({ target, label, variant }) => {
  return (
    <div className={`enkrateia-go-back-panel variant-${variant}`}>
      <Link to={target}>
        <ArrowLeftIcon size={14} color={variant === 'sidebar' ? '#FFF' : '#05445E'} />
        {label}
      </Link>
    </div>
  );
};

export default GoBackPanel;
