import { useMemo } from 'react';
import QuestionType from 'types/QuestionType';
import TraroElementResponse from 'types/api/TraroElementResponse';
import TraroElement from 'types/api/TraroElement';
import KliparoQuestion from 'types/api/KliparoQuestion';
import KliparoResponse from 'types/api/KliparoResponse';
import QuestionStatus from 'types/QuestionStatus';
import { FormikRichTextAreaValue } from '../../forms/formik/FormikRichTextAreaInput/FormikRichTextAreaInput';

export type QuestionFormValues =
  | { id: number | null; type: QuestionType.TEXT; value: string }
  | { id: number | null; type: QuestionType.LONG_TEXT; value: FormikRichTextAreaValue }
  | { id: number | null; type: QuestionType.URL; value: string }
  | { id: number | null; type: QuestionType.DISPLAY_ONLY; value: string }
  | { id: number | null; type: QuestionType.CHECKBOX; value: Record<string, boolean> }
  | { id: number | null; type: QuestionType.SELECTION; value: { value: string; label: string } | null };

export type FormValues = {
  id: number | undefined;
  score: number;
  questions: Record<number, QuestionFormValues>;
  questionStatus: QuestionStatus;
};

const createQuestion = (
  kliparoQuestion: KliparoQuestion,
  kliparoResponse: KliparoResponse | undefined | null,
): QuestionFormValues => {
  const type = kliparoQuestion.question_type;
  const kliparoResponseText = kliparoResponse?.kliparo_response ?? '';
  const id = kliparoResponse?.id ?? null;

  switch (type) {
    case QuestionType.TEXT:
    case QuestionType.URL:
    case QuestionType.DISPLAY_ONLY:
      return {
        id,
        type,
        value: kliparoResponseText,
      };
    case QuestionType.LONG_TEXT:
      return {
        id,
        type,
        value: {
          value: kliparoResponseText,
          editor: null,
        },
      };
    case QuestionType.CHECKBOX:
      const selectedValues = kliparoResponseText.split(';') ?? [];
      const value = kliparoQuestion.question_values.split(';').reduce((acc, elementValue) => {
        acc[window.btoa(elementValue)] = selectedValues.includes(elementValue);
        return acc;
      }, {} as Record<string, boolean>);

      return {
        id,
        type,
        value,
      };
    case QuestionType.SELECTION:
      if (!kliparoResponseText) {
        return {
          id,
          type,
          value: null,
        };
      }

      if (kliparoQuestion.question_values.split(';').includes(kliparoResponseText)) {
        return {
          id,
          type,
          value: { value: kliparoResponseText, label: kliparoResponseText },
        };
      }

      return {
        id,
        type,
        value: null,
      };
    default:
      throw new Error('Invalid question type: ' + type);
  }
};

const useInitialValues = (traroElement: TraroElement, elementResponse: TraroElementResponse): FormValues =>
  useMemo(() => {
    const score = elementResponse.question_score ?? 0;

    return traroElement.kliparo_question_list.reduce(
      (acc, kliparoQuestion) => {
        const kliparoResponse = elementResponse.kliparo_response_list?.find(
          (response) => response.kliparo_question?.id === kliparoQuestion.id,
        );

        acc.questions[kliparoQuestion.id] = createQuestion(kliparoQuestion, kliparoResponse);

        return acc;
      },
      {
        id: elementResponse.id,
        score,
        questions: {},
        questionStatus: elementResponse.question_status,
      } as FormValues,
    );
  }, [traroElement, elementResponse]);

export default useInitialValues;
