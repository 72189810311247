import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import archesSmall from 'assets/img/archesSmall.svg';
import helpIcon from 'assets/img/helpIcon.svg';
import bellIcon from 'assets/img/bellIcon.svg';
import { GlassesIcon, HomeIcon, InfoIcon } from 'components/SvgIcons';
import { useSelector } from 'store';
import { useSystemSettings } from 'providers/SystemSettingsProvider';

import UserDetails from './components/UserDetails/UserDetails';

import './Header.scss';

export interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);
  const { logo_image, portal_name, user_guide, show_portal_name_next_to_logo } = useSystemSettings();

  return (
    <header className="enkrateia-header">
      <div className="logo">
        <img src={logo_image} alt={portal_name} />
        {show_portal_name_next_to_logo && <h2 className="title">{portal_name}</h2>}
      </div>
      <nav>
        {isLoggedIn && (
          <>
            <NavLink className="nav-element" to="/dashboard">
              <HomeIcon size={24} color="#05445E" />
              {t('common.header.home')}
            </NavLink>
            <NavLink className="nav-element" to="/tickets">
              <GlassesIcon size={28} color="#05445E" />
              {t('common.header.tickets')}
            </NavLink>
          </>
        )}
        <a className="nav-element" href={t('common.header.aboutArchesUrl')} target="_blank" rel="noreferrer">
          <img src={archesSmall} alt="Arches" />
          {t('common.header.aboutArches')}
        </a>
        {t('common.header.aboutTraroUrl') !== '' && (
          <a className="nav-element" href={t('common.header.aboutTraroUrl')} target="_blank" rel="noreferrer">
            <InfoIcon size={24} color="#05445E" />
            {t('common.header.aboutTraro')}
          </a>
        )}
        <a className="nav-element" href={user_guide} target="_blank" rel="noreferrer">
          <img src={helpIcon} alt="Helps" />
          {t('common.header.userGuide')}
        </a>
        {isLoggedIn && (
          <>
            <div className="nav-element">
              <img src={bellIcon} alt="Bell" />
            </div>
            <UserDetails />
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
