import React, { ElementType, FC, ReactNode } from 'react';
import RCTooltip from 'rc-tooltip';
import { TooltipRef } from 'rc-tooltip/lib/Tooltip';

import 'rc-tooltip/assets/bootstrap.css';
import './Tooltip.scss';

type Variant = 'error' | 'default' | 'dark';
type ActionType = 'hover' | 'focus' | 'click' | 'contextMenu';

type TooltipPlacement = 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

const defaultTrigger: ActionType[] = ['hover'];

export interface TooltipProps {
  tooltip: string | ReactNode;
  placement?: TooltipPlacement;
  trigger?: ActionType[];
  variant?: Variant;
  InnerComponent?: ElementType;
  disabled?: boolean;
  onKeyUp?: React.KeyboardEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  children: ReactNode;
  overlayClassName?: string;
  tooltipRef?: React.Ref<TooltipRef>;
}

const Tooltip: FC<TooltipProps> = ({
  tooltip,
  placement = 'bottom',
  trigger = defaultTrigger,
  variant = 'default',
  InnerComponent = 'span',
  disabled,
  onKeyDown,
  onKeyUp,
  children,
  overlayClassName = '',
  tooltipRef,
}) => {
  if (disabled) {
    return <InnerComponent>{children}</InnerComponent>;
  }

  return (
    <RCTooltip
      overlay={tooltip}
      placement={placement}
      trigger={trigger}
      ref={tooltipRef}
      overlayClassName={`variant-${variant} ${overlayClassName}`}
    >
      <InnerComponent onKeyDown={onKeyDown} onKeyUp={onKeyUp} className="rc-tooltip-trigger-wrapper">
        {children}
      </InnerComponent>
    </RCTooltip>
  );
};

export default Tooltip;
