import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Footer from 'components/Footer/Footer';

import { useSystemSettings } from 'providers/SystemSettingsProvider';

import './Wrapper.scss';

export interface FormWrapperProps {
  children: React.ReactNode | React.ReactNode[];
}

const Wrapper: FC<FormWrapperProps> = ({ children }) => {
  const { t } = useTranslation();
  const { logo_image, portal_name, show_portal_name_next_to_logo, below_login_image } = useSystemSettings();

  return (
    <div className="enkrateia-splash-form-wrapper">
      <div className="form-container-root">
        <div className="form-container">
          <img src={logo_image} alt={portal_name} />
          <h2 className="app-platform">{show_portal_name_next_to_logo ? portal_name : null}</h2>
          {children}
          <div className="below-login-area">
            {t('pages.login.belowLoginText')}
            {below_login_image && <img src={below_login_image} alt="additional" />}
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Wrapper;
