import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import QuestionStatus from 'types/QuestionStatus';
import Button, { ButtonProps } from 'components/Button/Button';

import './QuestionStatusButton.scss';
import { CheckInCircleIcon, ExclamationInCircleIcon, InProgressBars } from '../SvgIcons';

export interface QuestionStatusButtonProps extends Omit<ButtonProps, 'variant' | 'children'> {
  status: QuestionStatus;
}

const getConfig = (status: QuestionStatus) => {
  switch (status) {
    case QuestionStatus.BEGIN:
      return {
        Icon: undefined,
        iconColor: '#000000',
        label: 'common.statusButton.begin',
        className: 'enkrateia-question-status-button begin',
      } as const;
    case QuestionStatus.UPDATE_REQUIRED:
      return {
        Icon: undefined,
        iconColor: '#000000',
        label: 'common.statusButton.updateRequired',
        className: 'enkrateia-question-status-button update-required',
      } as const;
    case QuestionStatus.REVIEW_ONLY:
      return {
        Icon: undefined,
        iconColor: '#000000',
        label: 'common.statusButton.reviewOnly',
        className: 'enkrateia-question-status-button review-only',
      } as const;
    case QuestionStatus.INCOMPLETE:
      return {
        Icon: ExclamationInCircleIcon,
        iconColor: '#ffffff',
        label: 'common.statusButton.incomplete',
        className: 'enkrateia-question-status-button incomplete',
      } as const;
    case QuestionStatus.IN_PROGRESS:
      return {
        Icon: InProgressBars,
        iconColor: '#000000',
        label: 'common.kliparoStatus.inProgress',
        className: 'enkrateia-question-status-button in-progress',
      } as const;
    case QuestionStatus.REVIEW_COMPLETE:
      return {
        Icon: CheckInCircleIcon,
        iconColor: '#ffffff',
        label: 'common.kliparoStatus.reviewComplete',
        className: 'enkrateia-question-status-button review-complete',
      } as const;
    case QuestionStatus.DONE:
    default:
      return {
        Icon: CheckInCircleIcon,
        iconColor: '#339D09',
        label: 'common.statusButton.done',
        className: 'enkrateia-question-status-button done',
      } as const;
  }
};

const QuestionStatusButton: FC<QuestionStatusButtonProps> = ({ status, className = '', ...props }) => {
  const { t } = useTranslation();

  const { Icon, iconColor, label, className: configuredClassName } = getConfig(status);

  return (
    <Button variant="primary" className={`${className} ${configuredClassName}`} {...props}>
      {Icon && <Icon color={iconColor} size={25} />}
      <span>{t(label)}</span>
    </Button>
  );
};

export default QuestionStatusButton;
