import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Background from 'components/Background/Background';
import TicketDetails from 'components/TicketDetails/TicketDetails';

import './TicketPage.scss';

export interface TicketPageProps {}

const TicketPage: FC<TicketPageProps> = () => {
  const { id } = useParams<{ id: string }>();

  if (id === undefined) {
    throw new Error();
  }

  return (
    <div className="enkrateia-ticket-detail-page">
      <Header />
      <main>
        <TicketDetails ticketId={id} />
      </main>
      <Footer />
      <Background />
    </div>
  );
};

export default TicketPage;
