import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'store';
import { useDownloadResponsePdfExport } from 'api';
import { canExportResponses } from 'services/permissions';
import useUser from 'hooks/useUser';
import { PdfIcon } from 'components/SvgIcons';
import Button from 'components/Button/Button';

import './ExportResponses.scss';

export interface ExportResponsesProps {}

const ExportResponses = ({}: ExportResponsesProps) => {
  const { t } = useTranslation();
  const rfpResponse = useSelector((store) => store.rfpResponse.data);
  const user = useUser();
  const exportResponses = useDownloadResponsePdfExport(rfpResponse.id);

  return (
    <div className="enkrateia-traro-export-responses">
      <Button variant="grey" onClick={exportResponses} disabled={!canExportResponses(user, rfpResponse)}>
        <PdfIcon color="#D8DADF" size={22} />
        {t('pages.projectOverview.sidePanel.exportTraroResponses')}
      </Button>
    </div>
  );
};

export default ExportResponses;
