import { useMemo } from 'react';
import { DateTime } from 'luxon';

import { InputOption } from 'components/forms/raw/SelectInput';
import TicketDto from 'types/api/TicketDto';
import UserDto from 'types/api/UserDto';
import TicketStatus from 'types/TicketStatus';
import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionSection from 'types/api/QuestionSection';
import TicketSeverityType from '../../../../../types/TicketSeverityType';
import TicketSourceType from '../../../../../types/TicketSourceType';

export type LinkedElementInputOption = InputOption<
  number | null,
  {
    response: TraroElementResponse;
    question: QuestionSection | null;
  }
>;

export interface TicketFormState {
  assignedTo: InputOption<number, UserDto>;
  dueDate: DateTime;
  linkedElement: LinkedElementInputOption | null;
  ticketDescription: string;
  ticketStatus: TicketStatus;
  originalTicket: TicketDto;
  severity: InputOption<TicketSeverityType> | null;
  category: InputOption<TicketSourceType> | null;
}

const useInitialValues = (
  ticket: TicketDto,
  selectableLinkedCriteria: LinkedElementInputOption[],
  severityOptions: InputOption<TicketSeverityType>[],
  categoryOptions: InputOption<TicketSourceType>[],
): TicketFormState => {
  console.log(selectableLinkedCriteria);

  return useMemo(
    () => ({
      assignedTo: {
        value: ticket.assigned_user.id,
        label: `${ticket.assigned_user.first_name} ${ticket.assigned_user.last_name}`,
        meta: ticket.assigned_user,
      },
      linkedElement:
        selectableLinkedCriteria.find((option) => option.value === ticket.traro_element_response_id) || null,
      severity: severityOptions.find((option) => option.value === ticket.severity_rating) || null,
      category: categoryOptions.find((option) => option.value === ticket.source) || null,
      ticketDescription: ticket.short_description,
      dueDate: DateTime.fromISO(ticket.due_date),
      ticketStatus: ticket.ticket_status,
      originalTicket: ticket,
    }),
    [ticket, selectableLinkedCriteria, severityOptions, categoryOptions],
  );
};

export default useInitialValues;
