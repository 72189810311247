import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import useUser from 'hooks/useUser';

export interface LoaderPlaceholderProps {
  ticketTitle: string;
  headerClassName: string;
  hasLinkedElement: boolean;
}

const LoaderPlaceholder = ({ ticketTitle, headerClassName, hasLinkedElement }: LoaderPlaceholderProps) => {
  const { t } = useTranslation();
  const { isReviewer } = useUser();

  return (
    <div className="enkrateia-ticket-form">
      <section className={headerClassName}>
        <div className="max-width">
          <h2>{ticketTitle}</h2>
        </div>
      </section>
      <Skeleton width={400} height={28} />
      <section className="form-elements">
        <label>{t('common.tickets.status')}:</label>
        <Skeleton width={150} height={28} />
        <label>{t('common.tickets.assignedTo')}:</label>
        <Skeleton width={200} height={28} />
        <label>{t('common.tickets.dueDate')}:</label>
        <Skeleton width={170} height={28} />
        {isReviewer && (
          <>
            <label>{t('common.tickets.severity')}:</label>
            <Skeleton width={180} height={28} />
          </>
        )}
        <label>{t('common.tickets.category')}:</label>
        <Skeleton width={140} height={28} />
        {hasLinkedElement && (
          <>
            <label>{t('common.tickets.linkedElement')}:</label>
            <Skeleton width={210} height={28} />
          </>
        )}
        <label>{t('common.tickets.ticketDescription')}:</label>
        <Skeleton width={450} height={150} />
      </section>
    </div>
  );
};

export default LoaderPlaceholder;
