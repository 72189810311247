import { useCallback } from 'react';

import { useSaveResponseScoringMutation } from 'api';
import TraroElementResponse from 'types/api/TraroElementResponse';
import { SaveScoringParams } from 'api/response/useSaveResponseScoringMutation';
import { useSelector } from 'store';

import { ElementScoreFormData } from './useInitialValues';
import { FormikHelpers } from 'formik';

const useFormSubmit = (rfpElementResponse: TraroElementResponse) => {
  const responseId = useSelector((state) => state.rfpResponse.data.id);
  const { mutateAsync: saveResponseScoring } = useSaveResponseScoringMutation(rfpElementResponse.id!, responseId);

  return useCallback(
    async (value: ElementScoreFormData, helpers: FormikHelpers<ElementScoreFormData>) => {
      const params: SaveScoringParams = {};

      for (const entry of Object.values(value)) {
        params[entry.elementScoreComponent.id] = {
          element_score_component_id: entry.elementScoreComponent.id,
          response_score:
            entry.responseScore !== '' ? Math.round(entry.responseScore * 100) / 100 : entry.responseScore,
          response_feedback: entry.responseFeedback.value,
        };

        value[entry.elementScoreComponent.id].responseScore = params[entry.elementScoreComponent.id].response_score;
      }

      await saveResponseScoring(params);

      helpers.setValues(value);
    },
    [saveResponseScoring],
  );
};

export default useFormSubmit;
