import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useUser from 'hooks/useUser';

const useInitialTab = () => {
  const user = useUser();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  // Run only on mount
  const initialTab = useMemo(() => {
    const initial = urlSearchParams.get('tab');

    if (initial !== null) {
      return initial;
    }

    return user.isReviewer ? 'question' : 'context';
  }, []);

  const [activeTab, setActiveTab] = useState<string>(initialTab);

  const onTabSwitch = useCallback(
    (tab: string) => {
      setActiveTab(tab);
      setUrlSearchParams((prevSearchParams) => new URLSearchParams({ ...prevSearchParams, tab }));
    },
    [setActiveTab, setUrlSearchParams, activeTab],
  );

  return {
    initialTab,
    activeTab,
    onTabSwitch,
  };
};

export default useInitialTab;
