import React, { useCallback, useEffect } from 'react';
import useSliderMarkConfig from '../../hooks/useSliderMarkConfig';
import { TooltipRef } from 'rc-tooltip/lib/Tooltip';
import raf from 'rc-util/lib/raf';
import RCTooltip from 'rc-tooltip';

export interface HandleProps {
  value: number;
  children: React.ReactElement;
  visible: boolean;
  tooltips: ReturnType<typeof useSliderMarkConfig>[1];
}

const Handle: React.FC<HandleProps> = (props) => {
  const { value, children, visible, tooltips, ...restProps } = props;

  const tooltipRef = React.useRef<TooltipRef>(null);
  const rafRef = React.useRef<number | null>(null);

  const cancelKeepAlign = useCallback(() => {
    raf.cancel(rafRef.current!);
  }, [rafRef]);

  const keepAlign = useCallback(() => {
    rafRef.current = raf(() => {
      tooltipRef.current?.forceAlign();
    });
  }, [rafRef, tooltipRef]);

  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <RCTooltip
      placement="top"
      overlayClassName="variant-default"
      overlay={tooltips?.[value] ?? value}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </RCTooltip>
  );
};
export default Handle;
